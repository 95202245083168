import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material/";
import Icons from "../../assets/icons";
import styles from "./SaveChangesModal.module.css";

const SaveChangesModal = ({
  isOpened,
  closeModal,
  handleClose,
  handleSave,
  closeSaveModal,
  header,
  handleUpdate,
  handleSubmit,
  text,
}) => {
  return (
    <Modal open={isOpened} onClose={closeModal}>
      <div className={styles.mainContainer}>
        <Icons.CloseIconDark
          onClick={closeModal}
          className={styles.closeIcon}
        />
        <p className={styles.title}>{header}</p>
        <p className={styles.text}>{text}</p>
        <div className={styles.buttons}>
          <button className={styles.noBtn} onClick={handleClose}>
            No
          </button>
          <button
            className={styles.yesBtn}
            onClick={() => {
              if (handleSubmit) {
                closeModal();
                handleSubmit(handleUpdate)();
              } else {
                handleSave();
              }
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

SaveChangesModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  header: PropTypes.string,
  text: PropTypes.string,
};

export default SaveChangesModal;
