import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';

const token = localStorage.getItem('token');
const tokenFromLocalStorage = token ? token : null;

const initialState = {
    admin: {
        loading: false,
        error: null,
        token: tokenFromLocalStorage,
    },
};

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;
