import React, { useState } from 'react';
import styles from './Tabs.module.css';

const Tabs = ({value, setTabValue}) => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
        setTabValue(!value);
    };

    return (
        <div className={styles.tabsContainer}>
            <button className={!isActive ? styles.active : ''} onClick={handleClick}>Personal Inventory</button>
            <button className={isActive ? styles.active : ''} onClick={handleClick}>Running low</button>
        </div>
    );
};

export default Tabs;