import {
    USER_CREATE_FAIL,
    USER_CREATE_REQUEST,
    USER_CREATE_SUCCESS,
    USERS_GET_SUCCESS,
    USER_GET_TYPE_SUCCESS,
    USER_GET_SUCCESS,
    USER_GET_REQUEST,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    CLEAR_ERROR
} from '../constants/userConstants';

const defaultState = {
    loading: true,
    userInfo: null,
    error: null,
    users: [],
    userTypes: [],
    count: 0,
    user: {},
};

export const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case USER_CREATE_REQUEST:
            return { ...state, loading: true };
        case USER_CREATE_SUCCESS:
            return { ...state, loading: false, userInfo: action.payload };
        case USER_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case USER_UPDATE_REQUEST:
            return { ...state, loading: true };
        case USER_UPDATE_SUCCESS:
            return { ...state, loading: false, user: action.payload };
        case USER_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case USER_GET_REQUEST:
            return { ...state, loading: true };
        case USER_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };

        case USER_DELETE_REQUEST:
            return { ...state, loading: true };
        case USER_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                user: {},
        };
        
        case USERS_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.rows,
                count: action.payload.count,
            };
        case USER_GET_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                userTypes: action.payload,
            };

        case CLEAR_ERROR: 
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};
