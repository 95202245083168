import * as React from "react";
import styles from "./Table.module.css";
import UsersInventoryPagination from "../../../UserSection/UsersInventory/Table/UsersInventoryPagination/UsersInventoryPagination";

export default function Table({ inventories }) {
  const itemsPerPage = 5;
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [displayedInventories, setDisplayedInventories] = React.useState([]);

  React.useEffect(() => {
    if (inventories) {
      setCount(Math.ceil(inventories.length / itemsPerPage));
      setDisplayedInventories(inventories.slice(0, itemsPerPage));
    }
  }, [inventories]);

  React.useEffect(() => {
    if (inventories) {
      setDisplayedInventories(
        inventories.slice(
          (page - 1) * itemsPerPage,
          (page - 1) * itemsPerPage + itemsPerPage
        )
      );
    }
  }, [page]);

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableColumn}>
            <th>Item name</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {displayedInventories &&
            displayedInventories.map((item) => (
              <tr key={item.id} id={item.id}>
                <td>{item.name}</td>
                <td>{item.category}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className={styles.paginationArea}>
        <UsersInventoryPagination setPage={setPage} page={page} count={count} />
      </div>
    </>
  );
}
