import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material/";
import Icons from "../../assets/icons";
import styles from "./DeleteUserModal.module.css";
import { useNavigate } from "react-router-dom";
import { popup } from "../../utils/popup";

const DeleteUserModal = ({ isOpened, closeModal, deleteUser }) => {
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteUser();
    closeModal();
    navigate("/users");
    popup("User deleted", "success");
  };

  return (
    <Modal open={isOpened} onClose={closeModal}>
      <div className={styles.mainContainer}>
        <Icons.CloseIconDark
          onClick={closeModal}
          className={styles.closeIcon}
        />
        <p className={styles.title}>Delete this user?</p>
        <p className={styles.text}>
          Are you sure that you what to delete this user? <br />
          You can not undo this action{" "}
        </p>
        <div className={styles.buttons}>
          <button className={styles.noBtn} onClick={closeModal}>
            No
          </button>
          <button className={styles.yesBtn} onClick={handleDelete}>
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteUserModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

export default DeleteUserModal;
