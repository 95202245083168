import * as AdminActionCreators from './adminActions';
import * as UserActionCreators from './userActions';
import * as InventoryActionCreators from './inventoryActions';
import * as StatisticActionCreators from './statisticActions';
import * as NewsActionCreators from './newsActions';

const actionCreators = {
    ...UserActionCreators,
    ...AdminActionCreators,
    ...InventoryActionCreators,
    ...StatisticActionCreators,
    ...NewsActionCreators
}

export default actionCreators;