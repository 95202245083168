/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import * as yup from 'yup';
import PropTypes from "prop-types";
import styles from "./CreateNews.module.css";
import Icons from "../../../assets/icons";
import { useParams, useNavigate } from "react-router-dom";
import FileUploader from "../../common/FileUploader/FileUploader";
import { SaveChangesModal } from "../../../components/index";
import { useActions } from "../../../hooks/useActions";
import { useDispatch, useSelector } from "react-redux";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { popup } from "../../../utils/popup";

export function useBlocker(blocker, when = true) {
  const { navigator } = React.useContext(NavigationContext);
  React.useEffect(() => {
    if (!when) return;
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

const CreateArticle = ({
  isOpenCreate,
  isOpenUpdate,
  closeCreate,
  closeUpdate,
  newsId,
  isVideoTab,
  setTitle,
  setDescription,
  title,
  description,
  handleModalSuccessOpen,
  handleModalErrorOpen,
}) => {
  const [permissionToNavigate, setPermissionToNavigate] = useState(false);
  const [routerPathName, setRouterPathName] = useState("/news");
  const [isChangeSmth, setIsChangeSmth] = useState(false);
  const blocker = (tx) => {
    setRouterPathName(tx.location.pathname);
    if (permissionToNavigate) {
      tx.retry();
      setPermissionToNavigate(false);
    }
    setSaveIsModalVisible(true);
  };
  useBlocker(blocker, isChangeSmth);

  const navigate = useNavigate();
  useEffect(() => {
    if (permissionToNavigate) {
      setTitle("");
      setDescription("");
      if (routerPathName !== "/news") {
        navigate(routerPathName);
      } else {
        closeCreate(false);
        closeUpdate(false);
      }
    }
  }, [permissionToNavigate]);

  const changesMiddleware = (func, args) => {
    func(args);
    setIsChangeSmth(true);
  };

  const [isSaveModalVisible, setSaveIsModalVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState();
  const [name, setName] = useState();
  const dispatch = useDispatch();

  const { getMe, getPosts, getPost, updatePost, createPost } = useActions();
  const { token } = useSelector((state) => state.admin);
  const { post } = useSelector((state) => state.news);

  const [error, setError] = useState(null);

  const closeSaveModal = () => {
    setSaveIsModalVisible(false);
    setTimeout(() => {
      setPermissionToNavigate(true);
    });
  };
  const closeForm = () => {
    if (title.length === 0 && description.length === 0 && !isOpenUpdate) {
      setSaveIsModalVisible(false);
      handleClose();
    } else {
      setSaveIsModalVisible(true);
    }
  };
  const handleClose = () => {
    if (isOpenCreate) {
      setTitle("");
      setDescription("");
      //closeCreate(false);
    } else if (isOpenUpdate) {
      setTitle("");
      setDescription("");
      //closeUpdate(false);
    }
    setTimeout(() => {
      setPermissionToNavigate(true);
    });
  };
  const handleSave = () => {
    setSaveIsModalVisible(false);
    if (isOpenCreate) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("name", files[0] ? files[0].path : name);
      formData.append("title", title);
      formData.append("description", description);
      //   const payload = {
      //     name: title,
      //     title: title,
      //     description: description,
      //   };
      if (title.length > 0 && description.length > 0 && files[0]) {
        createPost(formData).then(() => {
          getPosts(10, 1);
        });
        setTitle("");
        setDescription("");
        setError(null);
        //closeCreate(false);
        setPermissionToNavigate(true);
      } else {
        setError("Field is required");
      }
    } else if (isOpenUpdate) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("name", files[0] ? files[0].path : name);
      formData.append("title", title);
      formData.append("description", description);
      if (
        title.length > 0 &&
        description.length > 0 &&
        (image || files.length)
      ) {
        updatePost(newsId, formData).then(() => {
          getPosts(10, 1);
        });
        setError(null);
        //closeUpdate(false);
        setPermissionToNavigate(true);
      } else {
        setError("Field is required");
      }
    }
  };

  useEffect(() => {
    if (token) {
      if (isOpenUpdate) {
        getMe().then(() => getPost(newsId));
      }
    }
  }, [token]);

  useEffect(() => {
    if (token && isOpenUpdate && newsId === post?.id) {
      setTitle(post?.title);
      setDescription(post?.description);
      setImage(post?.image);
      setName(post?.name);
    }
  }, [post]);

  useEffect(() => {
    console.log("-----");
    console.log(post);
    return () => {
      dispatch({ type: "POST_CLEAN" });
      setTitle("");
      setDescription("");
    };
  }, []);

  return (
    <>
      <div className={styles.block}>
        <div className={styles.header}>
          <span>{isOpenCreate ? "Upload a new article" : "Edit article"}</span>
          <Icons.CloseIcon
            onClick={
              isChangeSmth
                ? closeForm
                : () => {
                    closeCreate(false);
                    closeUpdate(false);
                    setTitle("");
                    setDescription("");
                  }
            }
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.contentWrap}>
            <div>
              <FileUploader
                setIsChangeSmth={setIsChangeSmth}
                name={name}
                image={image}
                files={files}
                setFiles={setFiles}
                setImage={setImage}
              />

              {error && !files[0] && !image && (
                <p className={styles.textHelperImg}>{error}</p>
              )}
            </div>
            <div className={styles.inputsWrapper}>
              <div className={styles.wrapTitle}>
                <span className={styles.title}>Title</span>
                <input
                  className={styles.inputTitle}
                  value={title}
                  onChange={(event) => {
                    setIsChangeSmth(true);
                    setTitle(event.target.value);
                  }}
                />
                {error && title.length === 0 && (
                  <p className={styles.textHelper}>{error}</p>
                )}
              </div>
              <div className={styles.wrapContent}>
                <span className={styles.title}>Content</span>
                <textarea
                  className={styles.inputContent}
                  value={description}
                  onChange={(event) => {
                    setIsChangeSmth(true);
                    setDescription(event.target.value);
                  }}
                ></textarea>
                {error && description.length === 0 && (
                  <p className={styles.textHelper}>{error}</p>
                )}
              </div>
            </div>
          </div>
          <button className={styles.btn} onClick={handleSave}>
            {isOpenCreate ? "Publish" : "Save"}
          </button>
        </div>
      </div>
      <SaveChangesModal
        isOpened={isSaveModalVisible}
        closeModal={closeSaveModal}
        handleClose={handleClose}
        handleSave={handleSave}
        save={handleSave}
        header="Unsaved changes"
        text={
          isOpenCreate
            ? "Do you want to publish the article before leaving this page?"
            : "Do you want to save your changes before leaving this page?"
        }
      />
    </>
  );
};

CreateArticle.propTypes = {
  isOpenCreate: PropTypes.bool,
  isOpenUpdate: PropTypes.bool,
  closeCreate: PropTypes.func,
  closeUpdate: PropTypes.func,
  newsId: PropTypes.number,
};

export default CreateArticle;
