/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from '../StatisticSection.module.css';
import { useActions } from '../../../hooks/useActions';
import { useSelector } from 'react-redux';
import StatisticTable from '../StatisticTable/StatisticTable';
import Overview from '../Overview/Overview';
import DateFilter from '../DateFilter/DateFilter';

const ProductsTab = ({
    selectedCategory,
    setSelectedCategory,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateRange,
    setDateRange
}) => {
    const { getMe, getStatisticProducts } = useActions();

    const { token } = useSelector((state) => state.admin);
    const { statistics } = useSelector((state) => state.statistics);

    const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
    const [page, setPage] = useState(1);

    const PER_PAGE = 10;

    useEffect(() => {
        if (token) {
            if (startDate && endDate) {
                if (selectedCategory) {
                    const payload = {
                        limit: PER_PAGE,
                        page: page,
                        type: selectedCategory,
                        endDate: endDate,
                        startDate: startDate
                    };
        
                    getMe().then(() => getStatisticProducts(payload))
                } else {
                    const payload = {
                        limit: PER_PAGE,
                        page: page,
                        type: '',
                        endDate: endDate,
                        startDate: startDate
                    };
        
                    getMe().then(() => getStatisticProducts(payload))
                }
            } else {
                if (selectedCategory) {
                    const payload = {
                        limit: PER_PAGE,
                        page: page,
                        type: selectedCategory,
                        endDate: '',
                        startDate: ''
                    };
        
                    getMe().then(() => getStatisticProducts(payload))
                } else {
                    const payload = {
                        limit: PER_PAGE,
                        page: page,
                        type: '',
                        endDate: '',
                        startDate: ''
                    };
        
                    getMe().then(() => getStatisticProducts(payload))
                }
            }
        }
    }, [startDate, endDate, selectedCategory, page, token]);


    return (
        <div className={styles.blockWrap}>
            <div className={styles.blockArea}>
                <Overview 
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                />
                    <StatisticTable
                        isOpenDatePicker={isOpenDatePicker}
                        setIsOpenDatePicker={setIsOpenDatePicker}
                        inventories={statistics?.soldInventories?.rows}
                        page={page}
                        setPage={setPage}
                        count={Math.ceil(statistics?.soldInventories?.count / PER_PAGE)}
                        startDate={startDate}
                        endDate={endDate}
                    />
            </div>
            {isOpenDatePicker && 
                <DateFilter
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setIsOpenDatePicker={setIsOpenDatePicker}
                    startDate={startDate}
                    endDate={endDate}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            }
        </div>
    )
}

export default ProductsTab;
