import { ReactComponent as InventoryIcon } from './inventory.svg';
import { ReactComponent as LoginLeftFrame } from './loginLeftFrame.svg';
import { ReactComponent as LoginLogo } from './loginLogoFrame.svg';
import { ReactComponent as LoginRightFrame } from './loginRightFrame.svg';
import { ReactComponent as PaymentIcon } from './payment.svg';
import { ReactComponent as SidebarFrame } from './sidebarFrame.svg';
import { ReactComponent as SidebarLogo } from './sidebarLogoFrame.svg';
import { ReactComponent as StatisticsIcon } from './statistics.svg';
import { ReactComponent as UsersIcon } from './users.svg';
import { ReactComponent as CloseIcon } from './closeIcon.svg';
import { ReactComponent as CloseIconDark } from './closeIconDark.svg';
import { ReactComponent as CreateUserIcon } from './createUserIcon.svg';
import { ReactComponent as FormEmailIcon } from './formEmailIcon.svg';
import { ReactComponent as FormLoginIcon } from './formLoginIcon.svg';
import { ReactComponent as FormPasswordIcon } from './formPasswordIcon.svg';
import { ReactComponent as FormPaymentIcon } from './formPaymentIcon.svg';
import { ReactComponent as FormUserTypeIcon } from './formUserTypeIcon.svg';
import { ReactComponent as SelectFieldIcon } from './selectFieldIcon.svg';
import { ReactComponent as ContextDeleteIcon } from './contextDelete.svg';
import { ReactComponent as ContextEditIcon } from './contextEdit.svg';
import { ReactComponent as ContextViewProfileIcon } from './contextViewProfile.svg';
import { ReactComponent as Arrows } from './arrows.svg';
import { ReactComponent as Frame25 } from './frame25.svg';
import { ReactComponent as Frame26 } from './frame26.svg';
import { ReactComponent as PayIcon } from './pay.svg';
import { ReactComponent as PercentageIcon } from './percentage.svg';
import { ReactComponent as MoneyIcon } from './money.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { ReactComponent as DotsMenu } from './dotsmenu.svg';
import { ReactComponent as BackIcon } from './back.svg';
import { ReactComponent as LogoutIcon } from './logout.svg';
import { ReactComponent as PaymentWhiteIcon } from './paymentWhite.svg';
import { ReactComponent as InventoryNewIcon } from './inventoryNew.svg';
import { ReactComponent as NewsIcon } from './news.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as UploadIcon } from './upload.svg';
import { ReactComponent as ActivityHistoryIcon } from './teenyicons_history-solid.svg';
import { ReactComponent as ArrowUpIcon } from './arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from './arrow-down.svg';
import { ReactComponent as DollarIcon } from './dollar.svg';
import { ReactComponent as TierIcon } from './tier.svg';

const icons = {
    InventoryIcon,
    LoginLeftFrame,
    LoginLogo,
    LoginRightFrame,
    PaymentIcon,
    SidebarFrame,
    SidebarLogo,
    StatisticsIcon,
    UsersIcon,
    CloseIcon,
    CreateUserIcon,
    FormEmailIcon,
    FormLoginIcon,
    FormPasswordIcon,
    FormPaymentIcon,
    FormUserTypeIcon,
    SelectFieldIcon,
    ContextDeleteIcon,
    ContextEditIcon,
    ContextViewProfileIcon,
    Arrows,
    Frame25,
    Frame26,
    PayIcon,
    PercentageIcon,
    MoneyIcon,
    CloseIconDark,
    CalendarIcon,
    DotsMenu,
    BackIcon,
    LogoutIcon,
    PaymentWhiteIcon,
    InventoryNewIcon,
    NewsIcon,
    EditIcon,
    DeleteIcon,
    PlusIcon,
    UploadIcon,
    ActivityHistoryIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    DollarIcon,
    TierIcon
};

export default icons;
