import axios from "axios";

export const BASE_URL = "https://blushedtest.vironit.com";

export const get = async (url) => {
  const token = getToken();
  return axios.get(`${BASE_URL}${url}`, createHeader(token));
};

export const post = async (url, data) => {
  const token = getToken();
  return axios.post(BASE_URL + url, data || {}, createHeader(token));
};

export const postFiles = async (url, data) => {
  const token = getToken();
  return axios.post(BASE_URL + url, data || {}, {
    headers: { Authorization: `Bearer ${token}` },
    "Content-Type": "multipart/form-data",
  });
};

export const putFiles = async (url, data) => {
  const token = getToken();
  return axios.put(BASE_URL + url, data || {}, {
    headers: { Authorization: `Bearer ${token}` },
    "Content-Type": "multipart/form-data",
  });
};

export const put = async (url, data) => {
  const token = getToken();
  return axios.put(BASE_URL + url, data || {}, createHeader(token));
};

export const patch = async (url, data) => {
  const token = getToken();
  return axios.patch(BASE_URL + url, data ?? {}, createHeader(token));
};

export const deleteOne = async (url) => {
  const token = getToken();
  return axios.delete(BASE_URL + url, getConfig(token));
};

export const deleteAll = async (url) => {
  const token = getToken();
  return axios.delete(BASE_URL + url, getConfig(token));
};

const getConfig = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});

const createHeader = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});

export function getToken() {
  return localStorage.getItem("token");
}
