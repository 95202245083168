import "./App.css";
import Login from "./pages/Login/Login";
import { Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import RestorePassword from "./pages/RestorePassword/RestorePassword";
import ChangePassword from "./pages/RestorePassword/ChangePassword";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  NewsSection,
  UsersSection,
  UserSection,
  StatisticSection,
  PaymentSection,
  InventorySection,
  ActivityHistorySection,
} from "./components/index";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/statistics" element={<StatisticSection />} />
          <Route path="/users" element={<UsersSection />} />
          <Route path="/users/:id" element={<UserSection view />} />
          <Route path="/users/:id/edit" element={<UserSection edit />} />
          <Route path="/payment" element={<PaymentSection />} />
          <Route path="/inventory" element={<InventorySection />} />
          <Route path="/news" element={<NewsSection />} />
          <Route
            path="/activity-history"
            element={<ActivityHistorySection />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/restorepassword" element={<RestorePassword />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        {/* <Route path='/signup' element={<SignUp />} /> */}
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
