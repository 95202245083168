import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import "react-contexify/dist/ReactContexify.css";
import StatisticPagination from "./StatisticPagination/StatisticPagination";
import styles from "./StatisticTable.module.css";
import Icons from "../../../assets/icons";

const toDateFormat = (milliseconds) => {
  if (!milliseconds) return "";
  return new Date(milliseconds)
    .toLocaleTimeString([], {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .slice(0, 8);
};

const StatisticTable = ({
  inventories = [],
  setPage,
  page,
  count,
  isOpenDatePicker,
  setIsOpenDatePicker,
  className,
  startDate,
  endDate,
}) => {
  return (
    <Grid item xs={12}>
      <div className={styles.wrap}>
        <div className={styles.title}>
          {toDateFormat(startDate)} {startDate && "-"} {toDateFormat(endDate)}
        </div>
        <div
          className={styles.selectWrap}
          onClick={() => setIsOpenDatePicker(!isOpenDatePicker)}
        >
          <div>{`Date filter`}</div>
          <Icons.SelectFieldIcon
            className={`${isOpenDatePicker && styles.activeArrow} ${className}`}
          />
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr className={styles.tableColumn}>
            <th>Description</th>
            <th>Quantity</th>
            <th>Net Cost</th>
            <th>Selling Price</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {inventories?.map((item) => (
            <tr key={item.id} id={item.id}>
              <td>{item.category}</td>
              <td>{item.quantity}</td>
              <td>{`USD ${item.netCost}`}</td>
              <td>{`USD ${item.sellingPrice}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.paginationArea}>
        <StatisticPagination setPage={setPage} page={page} count={count} />
      </div>
    </Grid>
  );
};

StatisticTable.propTypes = {
  inventories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  setPage: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
  isOpenDatePicker: PropTypes.bool,
  setIsOpenDatePicker: PropTypes.func,
  className: PropTypes.object,
};

export default StatisticTable;
