import {
  VIDEOS_GET_REQUEST,
  VIDEOS_GET_SUCCESS,
  VIDEOS_GET_FAIL,
  VIDEO_GET_REQUEST,
  VIDEO_GET_SUCCESS,
  VIDEO_GET_FAIL,
  VIDEO_CREATE_REQUEST,
  VIDEO_CREATE_SUCCESS,
  VIDEO_CREATE_FAIL,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAIL,
  VIDEO_DELETE_REQUEST,
  VIDEO_DELETE_SUCCESS,
  VIDEO_DELETE_FAIL,
  POSTS_GET_REQUEST,
  POSTS_GET_SUCCESS,
  POSTS_GET_FAIL,
  POST_GET_REQUEST,
  POST_GET_SUCCESS,
  POST_GET_FAIL,
  POST_CREATE_REQUEST,
  POST_CREATE_SUCCESS,
  POST_CREATE_FAIL,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAIL,
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,
  POST_CLEAN,
} from "../constants/newsConstants";

const defaultState = {
  videos: [],
  video: {},
  posts: [],
  post: {},
  error: null,
  loading: true,
};

export const newsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case VIDEOS_GET_REQUEST:
      return { ...state, loading: true };
    case VIDEOS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: action.payload,
      };
    case VIDEOS_GET_FAIL:
      return { ...state, loading: true, error: action.payload };

    case VIDEO_GET_REQUEST:
      return { ...state, loading: true };
    case VIDEO_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        video: action.payload,
      };
    case VIDEO_GET_FAIL:
      return { ...state, loading: true, error: action.payload };

    case VIDEO_CREATE_REQUEST:
      return { ...state, loading: true };
    case VIDEO_CREATE_SUCCESS:
      return { ...state, loading: false, video: action.payload };
    case VIDEO_CREATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case VIDEO_UPDATE_REQUEST:
      return { ...state, loading: true };
    case VIDEO_UPDATE_SUCCESS:
      return { ...state, loading: false, video: action.payload };
    case VIDEO_UPDATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case VIDEO_DELETE_REQUEST:
      return { ...state, loading: true };
    case VIDEO_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        video: {},
      };
    case VIDEO_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case POSTS_GET_REQUEST:
      return { ...state, loading: true };
    case POSTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.payload,
      };
    case POSTS_GET_FAIL:
      return { ...state, loading: true, error: action.payload };

    case POST_GET_REQUEST:
      return { ...state, loading: true };
    case POST_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.payload,
      };
    case POST_GET_FAIL:
      return { ...state, loading: true, error: action.payload };

    case POST_CREATE_REQUEST:
      return { ...state, loading: true };
    case POST_CREATE_SUCCESS:
      return { ...state, loading: false, post: action.payload };
    case POST_CREATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case POST_UPDATE_REQUEST:
      return { ...state, loading: true };
    case POST_UPDATE_SUCCESS:
      return { ...state, loading: false, post: action.payload };
    case POST_CLEAN:
      return { ...state, post: {} };
    case POST_UPDATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case POST_DELETE_REQUEST:
      return { ...state, loading: true };
    case POST_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        post: {},
      };
    case POST_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
