/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CustomSelect from "../../common/CustomSelect/CustomSelect";
import styles from "./Overview.module.css";
import { useSelector } from "react-redux";
import { useActions } from "../../../hooks/useActions";

const Overview = ({ selectedCategory, setSelectedCategory }) => {
  const { getInventoryTypes, getMe } = useActions();
  const { statistics } = useSelector((state) => state.statistics);
  const { inventoryTypes } = useSelector((state) => state.inventory);
  const [formatCategories, setFormatCategories] = useState([]);

  useEffect(() => {
    if (inventoryTypes) {
      const newCategories = inventoryTypes.map((item) => {
        return {
          id: item.id,
          value: item.name,
        };
      });
      newCategories.unshift({
        id: 0,
        value: "All",
      });
      setFormatCategories(newCategories);
    }
  }, [inventoryTypes]);

  useEffect(() => {
    getMe().then(() => getInventoryTypes());
  }, []);

  return (
    <Grid item xs={6}>
      <div className={styles.container}>
        <div className={styles.wrapSelect}>
          <span>Category:</span>
          <CustomSelect
            // all={true}
            name="Category"
            options={formatCategories}
            className={styles.select}
            additionalProps={{
              value: selectedCategory,
              onChange: (event) => setSelectedCategory(event.target.value),
            }}
          />
        </div>
        <div className={styles.wrap}>
          <span>Total Sales</span>
          <span>{`USD ${
            statistics?.totalSales ? statistics?.totalSales : ""
          }`}</span>
        </div>
        <div className={styles.wrap}>
          <span>Total net cost</span>
          <span>{`USD ${
            statistics?.totalNetCost ? statistics?.totalNetCost : ""
          }`}</span>
        </div>
        <div className={styles.wrap}>
          <span>Total profit</span>
          <span>{`USD ${
            statistics?.totalProfit ? statistics?.totalProfit : ""
          }`}</span>
        </div>
        <div className={styles.wrap}>
          <span>Quantity</span>
          <span>{`${statistics?.quantity ? statistics?.quantity : ""}`}</span>
        </div>
        <div className={styles.wrapCategory}>
          <span>Total Sales by Category</span>
          <table className={styles.table}>
            <tbody className={styles.tableBody}>
              {statistics.categoriesStat?.map((item, index) => (
                <tr key={item.id} id={item.id}>
                  <td>{`${index + 1}. ${item.category}`}</td>
                  <td>{`${item.quantity} PCs`}</td>
                  <td>{`${item.totalSales} usd`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Grid>
  );
};

Overview.propTypes = {
  selectedCategory: PropTypes.number,
  setSelectedCategory: PropTypes.func,
};

export default Overview;
