/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import styles from "./ActivityHistorySection.module.css";
import { Grid, CircularProgress } from "@mui/material";
import ActivityHistoryHeader from "../common/Header/Header";
import ActivityHistoryTable from "./ActivityHistoryTable/ActivityHistoryTable";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import DatePicker from "../common/DatePicker/DatePicker";

const ActivityHistorySection = () => {
  const { token } = useSelector((state) => state.admin);
  const { count, loading, activityHistory } = useSelector(
    (state) => state.statistics
  );
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const { getActivityHistory, getMe } = useActions();
  const [filter, setFilter] = useState("");
  const [dateValue, setDateValue] = useState(dayjs(new Date()).valueOf());

  const [debounce, setDebounce] = useState(null);
  const [isClosed, setIsClosed] = useState(true);

  const onCloseHandle = () => {
    setIsClosed(true);
  };

  const onOpenHandle = () => {
    setIsClosed(false);
  };

  const debounceSearch = (fn) => {
    clearTimeout(debounce);
    setDebounce(setTimeout(fn, 700));
  };

  const getFiltered = () => {
    try {
      const payload = {
        date: dateValue,
        limit: PER_PAGE,
        page: page,
        search: filter,
      };
      if (isClosed) {
        getMe().then(() => getActivityHistory(payload));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (token && dateValue) {
      debounceSearch(getFiltered());
    }
  }, [isClosed, filter]);

  useEffect(() => {
    if (token && dateValue) {
      getFiltered();
    }
  }, [page]);

  return (
    <main className={styles.mainArea}>
      <Grid container>
        <ActivityHistoryHeader
          title="Activity history"
          search
          setFilter={setFilter}
          activityHistory
          dateValue={dateValue}
          setDateValue={setDateValue}
          onCloseHandle={onCloseHandle}
          onOpenHandle={onOpenHandle}
        />

        {loading && <CircularProgress />}
        {!loading && (
          <ActivityHistoryTable
            history={activityHistory}
            page={page}
            setPage={setPage}
            count={Math.ceil(count / PER_PAGE)}
            date={dateValue}
            setDate={setDateValue}
            setIsClosed={setIsClosed}
          />
        )}
      </Grid>
    </main>
  );
};

export default ActivityHistorySection;
