import {
  VIDEOS_GET_REQUEST,
  VIDEOS_GET_SUCCESS,
  VIDEOS_GET_FAIL,
  VIDEO_GET_REQUEST,
  VIDEO_GET_SUCCESS,
  VIDEO_GET_FAIL,
  VIDEO_CREATE_REQUEST,
  VIDEO_CREATE_SUCCESS,
  VIDEO_CREATE_FAIL,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAIL,
  VIDEO_DELETE_REQUEST,
  VIDEO_DELETE_SUCCESS,
  VIDEO_DELETE_FAIL,
  POSTS_GET_REQUEST,
  POSTS_GET_SUCCESS,
  POSTS_GET_FAIL,
  POST_GET_REQUEST,
  POST_GET_SUCCESS,
  POST_GET_FAIL,
  POST_CREATE_REQUEST,
  POST_CREATE_SUCCESS,
  POST_CREATE_FAIL,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAIL,
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,
} from "../constants/newsConstants";
import {
  get,
  post,
  postFiles,
  putFiles,
  put,
  deleteOne,
} from "../../services/fetchData";
import { popup } from "../../utils/popup";

export const getVideos = (limit, page) => async (dispatch) => {
  try {
    dispatch({ type: VIDEOS_GET_REQUEST });
    const { data } = await get(`/video?limit=${limit}&page=${page}`);
    dispatch({ type: VIDEOS_GET_SUCCESS, payload: data.rows });
  } catch (error) {
    dispatch({ type: VIDEOS_GET_FAIL, payload: error.message });
  }
};
export const getVideo = (id) => async (dispatch) => {
  try {
    dispatch({ type: VIDEO_GET_REQUEST });
    await get(`/video/${id}`).then((res) =>
      dispatch({ type: VIDEO_GET_SUCCESS, payload: res.data })
    );
  } catch (error) {
    dispatch({ type: VIDEO_GET_FAIL, payload: error.message });
  }
};
export const createVideo = (video) => async (dispatch) => {
  try {
    dispatch({ type: VIDEO_CREATE_REQUEST });
    const { data } = await post("/video", video);
    dispatch({ type: VIDEO_CREATE_SUCCESS, payload: data });
    popup("Video created", "success");
  } catch (error) {
    dispatch({ type: VIDEO_CREATE_FAIL, payload: error.message });
    popup("Video cannot be created", "error");
  }
};
export const updateVideo = (id, video) => async (dispatch) => {
  try {
    dispatch({ type: VIDEO_UPDATE_REQUEST });
    const { data } = await put(`/video/${id}`, video);
    dispatch({ type: VIDEO_UPDATE_SUCCESS, payload: data });
    popup("Video updated", "success");
  } catch (error) {
    dispatch({ type: VIDEO_UPDATE_FAIL, payload: error.message });
    popup("Video cannot be updated", "error");
  }
};
export const deleteVideo = (id) => async (dispatch) => {
  try {
    dispatch({ type: VIDEO_DELETE_REQUEST });
    const { data } = await deleteOne(`/video/${id}`);
    dispatch({ type: VIDEO_DELETE_SUCCESS, payload: data });
    popup("Video deleted", "success");
  } catch (error) {
    dispatch({ type: VIDEO_DELETE_FAIL, payload: error.message });
    popup("Video cannot be deleted", "error");
  }
};

export const getPosts = (limit, page) => async (dispatch) => {
  try {
    dispatch({ type: POSTS_GET_REQUEST });
    const { data } = await get(`/posts?limit=${limit}&page=${page}`);
    dispatch({ type: POSTS_GET_SUCCESS, payload: data.rows });
  } catch (error) {
    dispatch({ type: POSTS_GET_FAIL, payload: error.message });
  }
};
export const getPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: POST_GET_REQUEST });
    await get(`/posts/${id}`).then((res) =>
      dispatch({ type: POST_GET_SUCCESS, payload: res.data })
    );
  } catch (error) {
    dispatch({ type: POST_GET_FAIL, payload: error.message });
  }
};
export const createPost = (payload) => async (dispatch) => {
  try {
    dispatch({ type: POST_CREATE_REQUEST });
    const { data } = await postFiles("/posts", payload);
    dispatch({ type: POST_CREATE_SUCCESS, payload: data });
    popup("Article created", "success");
  } catch (error) {
    console.log(error);
    dispatch({ type: POST_CREATE_FAIL, payload: error.message });
    popup("Article cannot be created", "error");
  }
};
export const updatePost = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: POST_UPDATE_REQUEST });
    const { data } = await putFiles(`/posts/${id}`, payload);
    dispatch({ type: POST_UPDATE_SUCCESS, payload: data });
    popup("Article updated", "success");
  } catch (error) {
    dispatch({ type: POST_UPDATE_FAIL, payload: error.message });
    popup("Article cannot be updated", "error");
  }
};
export const deletePost = (id) => async (dispatch) => {
  try {
    dispatch({ type: POST_DELETE_REQUEST });
    const { data } = await deleteOne(`/posts/${id}`);
    dispatch({ type: POST_DELETE_SUCCESS, payload: data });
    popup("Article deleted", "success");
  } catch (error) {
    dispatch({ type: POST_DELETE_FAIL, payload: error.message });
    popup("Article cannot be deleted", "error");
  }
};
