/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./DateFilter.module.css";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import CustomSelect from "../../common/CustomSelect/CustomSelect";
import DatePicker from "../../common/DatePicker/DatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

export default function SubComponentsPickers({
  setIsOpenDatePicker,
  className,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  dateRange,
  setDateRange,
}) {
  const now = new Date();
  const [startDateValue, setStartDateValue] = useState();
  const [endDateValue, setEndDateValue] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const [dateRanges] = useState([
    {
      id: 1,
      value: "Today",
    },
    {
      id: 2,
      value: "Last Week",
    },
    {
      id: 3,
      value: "Last Month",
    },
  ]);

  const wrapper = {
    width: "100%",
    boxShadow: "-1px 2px 7px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#ffffff",
    display: "flex",
    flexWrap: "nowrap",
  };

  const handlerApply = () => {
    setStartDate(dayjs(startDateValue).valueOf());
    setEndDate(dayjs(endDateValue).valueOf());
    setIsOpenDatePicker(false);
  };

  const checkValidInterval = () => {
    let newStart = new Date(startDateValue);
    let newEnd = new Date(endDateValue);
    if (startDateValue < endDateValue) {
      setIsDisabled(false);
    } else if (
      dayjs(newStart).format("DD/MM/YYYY") ===
      dayjs(newEnd).format("DD/MM/YYYY")
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    checkValidInterval();
  }, [startDateValue, endDateValue]);

  useEffect(() => {
    switch (dateRange) {
      case 1: {
        setStartDateValue(dayjs(now).valueOf());
        setEndDateValue(dayjs(now).valueOf());
        return;
      }
      case 2: {
        setStartDateValue(dayjs(now).valueOf() - 6.048e8);
        setEndDateValue(dayjs(now).valueOf() - 8.64e7);
        return;
      }
      case 3: {
        setStartDateValue(dayjs(now).valueOf() - 2.628e9);
        setEndDateValue(dayjs(now).valueOf());
        return;
      }

      default:
        return;
    }
  }, [dateRange]);

  useEffect(() => {
    if (startDate && endDate) {
      setStartDateValue(startDate);
      setEndDateValue(endDate);
    } else {
      setStartDateValue(dayjs(now).valueOf());
      setEndDateValue(dayjs(now).valueOf());
      setDateRange(dateRanges[0].id);
    }
  }, []);

  return (
    <div className={`${styles.wrap} ${className}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={3} style={wrapper}>
          <Grid item xs={12} md={6} style={{ width: "30%", paddingLeft: 0 }}>
            <CalendarPicker
              date={dayjs(startDateValue)}
              onChange={(newDate) =>
                setStartDateValue(dayjs(newDate).valueOf())
              }
              onYearChange={() =>
                document
                  .getElementsByClassName("MuiPickersCalendarHeader-label")[0]
                  .click()
              }
              sx={{ ".MuiCalendarPicker-root": { width: "100%" } }}
              maxDate={endDateValue}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ width: "30%", paddingLeft: 0 }}>
            {" "}
            <CalendarPicker
              date={dayjs(endDateValue)}
              onChange={(newDate) => setEndDateValue(dayjs(newDate).valueOf())}
              onYearChange={() =>
                document
                  .getElementsByClassName("MuiPickersCalendarHeader-label")[1]
                  .click()
              }
              minDate={startDateValue}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ width: "40%", paddingRight: "34px", color: "#555555" }}
          >
            <span>Date range</span>
            <CustomSelect
              name="dateRange"
              options={dateRanges}
              className={styles.select}
              additionalProps={{
                value: dateRange,
                onChange: (event) => {
                  setDateRange(event.target.value);
                },
              }}
            />
            <div className={styles.wrapRow}>
              <div className={styles.wrapColumn}>
                <span>Starting</span>
                <DatePicker
                  disableOpenPicker={true}
                  value={startDateValue}
                  setValue={setStartDateValue}
                />
                {/* <StaticDatePicker defaultValue={dayjs("2022-04-17")} /> */}
              </div>
              <div className={styles.wrapColumn}>
                <span>Ending</span>
                <DatePicker
                  disableOpenPicker={true}
                  value={endDateValue}
                  setValue={setEndDateValue}
                />
              </div>
            </div>
            {isDisabled && (
              <p className={styles.textHelper}>invalid interval</p>
            )}
            <div className={styles.buttons}>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpenDatePicker(false)}
              >
                Cancel
              </button>
              <button
                className={styles.applyBtn}
                onClick={() => handlerApply()}
                disabled={isDisabled}
              >
                Apply
              </button>
            </div>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  );
}
