import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import Icons from "../../../assets/icons";
import CustomSelect from "../../common/CustomSelect/CustomSelect";
import CustomTextInput from "../../common/CustomTextInput/CustomTextInput";
import DatePicker from "../../common/DatePicker/DatePicker";
import { useSelector } from "react-redux";

const Commission = ({
  commissionTypes,
  selectedCommissionType,
  setSelectedCommissionType,
  selectedCommission,
  setSelectedCommission,
  view,
  dateStartValue,
  setDateStartValue,
  onCloseHandle,
  onOpenHandle,
  commissionError,
}) => {
  const { paymentPrograms, tiers } = useSelector((state) => state.statistics);
  return (
    <>
      {!view && (
        <>
          <div className={styles.container}>
            <div className={styles.wrap}>
              <div className={styles.formInfo}>
                <Icons.CalendarIcon className={styles.icon} />
                <p>Period</p>
              </div>
              <DatePicker
                className={styles.datePicker}
                value={dateStartValue}
                setValue={setDateStartValue}
                views={["year", "month"]}
                inputFormat={"MM/YYYY"}
                onClose={onCloseHandle}
                onOpen={onOpenHandle}
                openTo="month"
              />
            </div>
            <div className={styles.wrap}>
              <div className={styles.formInfo}>
                <Icons.PayIcon className={styles.icon} />
                <p>Commission type</p>
              </div>
              <CustomSelect
                name="selectedCommissionType"
                options={commissionTypes}
                className={styles.select}
                additionalProps={{
                  value: selectedCommissionType,
                  onChange: (event) => {
                    let paymentProgram = paymentPrograms.find(
                      (el) => el.id === event.target.value
                    );
                    setSelectedCommission(paymentProgram.commission);
                    setSelectedCommissionType(event.target.value);
                  },
                }}
              />
            </div>
            {selectedCommissionType !== 3 ? (
              <div className={styles.wrap}>
                <div className={styles.formInfo}>
                  <Icons.PercentageIcon className={styles.icon} />
                  <p>Commission</p>
                </div>
                <CustomTextInput
                  id="selectedCommission"
                  name="selectedCommission"
                  // type='number'
                  className={styles.select}
                  additionalProps={{
                    value: selectedCommission ? selectedCommission : "",
                    onChange: (event) => {
                      setSelectedCommission(event.target.value);
                    },
                  }}
                />
                {commissionError && (
                  <p className={styles.textHelper}>{commissionError}</p>
                )}
              </div>
            ) : null}
          </div>
          {selectedCommissionType === 3 && (
            <div className={styles.container}>
              {tiers.map((tier, index) => (
                <div key={index} className={styles.wrap}>
                  <div className={styles.formInfo}>
                    <Icons.PercentageIcon className={styles.icon} />
                    <p>Tier {index + 1} commission</p>
                  </div>
                  <CustomTextInput
                    disable
                    id="selectedCommission"
                    name="selectedCommission"
                    // type='number'
                    className={styles.select}
                    additionalProps={{
                      value: tier.commission,
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {view && (
        <>
          <div className={styles.container}>
            <div className={styles.wrap}>
              <div className={styles.formInfo}>
                <Icons.CalendarIcon className={styles.icon} />
                <p>Period</p>
              </div>
              <DatePicker
                className={styles.datePicker}
                value={dateStartValue}
                setValue={setDateStartValue}
                views={["year", "month"]}
                inputFormat={"MM/YYYY"}
                onClose={onCloseHandle}
                onOpen={onOpenHandle}
                openTo="month"
                disableOpenPicker={view}
                disabled={view}
              />
            </div>
            <div className={styles.wrap}>
              <div className={styles.formInfo}>
                <Icons.PayIcon className={styles.icon} />
                <p>Commission type</p>
              </div>
              <CustomTextInput
                id="commissionType"
                name="commissionType"
                className={styles.select}
                disable={view}
                additionalProps={{
                  value: selectedCommissionType ? selectedCommissionType : "",
                  onChange: (event) =>
                    setSelectedCommissionType(event.target.value),
                }}
              />
            </div>
            {selectedCommissionType !== "Tiered Commission" ? (
              <div className={styles.wrap}>
                <div className={styles.formInfo}>
                  <Icons.PercentageIcon className={styles.icon} />
                  <p>Commission</p>
                </div>
                <CustomTextInput
                  id="selectedCommission"
                  name="selectedCommission"
                  className={styles.select}
                  additionalProps={{
                    value: selectedCommission ? selectedCommission : "",
                    onChange: (event) =>
                      setSelectedCommission(event.target.value),
                  }}
                  disable={view}
                />
              </div>
            ) : null}
          </div>
          {selectedCommissionType === "Tiered Commission" && (
            <div className={styles.container}>
              {tiers.map((tier, index) => (
                <div key={index} className={styles.wrap}>
                  <div className={styles.formInfo}>
                    <Icons.PercentageIcon className={styles.icon} />
                    <p>Tier {index + 1} commission</p>
                  </div>
                  <CustomTextInput
                    disable
                    id="selectedCommission"
                    name="selectedCommission"
                    // type='number'
                    className={styles.select}
                    additionalProps={{
                      value: tier.commission,
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

Commission.propTypes = {
  commissionTypes: PropTypes.array,
  selectedCommissionType: PropTypes.number,
  setSelectedCommissionType: PropTypes.func,
  selectedCommission: PropTypes.number,
  setSelectedCommission: PropTypes.func,
  view: PropTypes.bool,
};

export default Commission;
