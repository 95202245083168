import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import Icons from '../../../assets/icons';

const CondSection = ({ commission, totalSales, toPay }) => {

    return (
        <div className={styles.wrap}>
            <div className={styles.block}>
                <div className={styles.img}>
                    <Icons.MoneyIcon className={styles.icon} />
                </div>
                <div className={styles.titlesWrap}>
                    <span className={styles.title}>Total Sales</span>
                    <span className={styles.subTitle}>{totalSales ? `${totalSales} USD` : '0 USD'}</span>
                </div>
                
            </div>
            <div className={styles.block}>
                <div className={styles.img}>
                    <Icons.PercentageIcon className={styles.icon} />
                </div>
                <div className={styles.titlesWrap}>
                    <span className={styles.title}>Commision</span>
                    <span className={styles.subTitle}>{commission ? `${commission} %` : '0 %'}</span>
                </div>
                
            </div>
            <div className={styles.block}>
                <div className={styles.img}>
                    <Icons.PayIcon className={styles.icon} />
                </div>
                <div className={styles.titlesWrap}>
                    <span className={styles.title}>To Pay</span>
                    <span className={styles.subTitle}>{toPay ? `${toPay} USD` : '0 USD'}</span>
                </div>
                
            </div>
        </div>
    );
};

CondSection.propTypes = {
    commission: PropTypes.number,
    totalSales: PropTypes.number,
};

export default CondSection;