export const STATISTIC_GET_REQUEST = 'STATISTIC_GET_REQUEST';
export const STATISTIC_GET_SUCCESS = 'STATISTIC_GET_SUCCESS';
export const STATISTIC_GET_FAIL = 'STATISTIC_GET_FAIL';
export const STATISTIC_CLEAR_STATE = 'STATISTIC_CLEAR_STATE';

export const ACTIVITY_HISTORY_GET_REQUEST = 'ACTIVITY_HISTORY_GET_REQUEST';
export const ACTIVITY_HISTORY_GET_SUCCESS = 'ACTIVITY_HISTORY_GET_SUCCESS';
export const ACTIVITY_HISTORY_GET_FAIL = 'ACTIVITY_HISTORY_GET_FAIL';

export const TIERS_GET_REQUEST = 'TIERS_GET_REQUEST';
export const TIERS_GET_SUCCESS = 'TIERS_GET_SUCCESS';
export const TIERS_GET_FAIL = 'TIERS_GET_FAIL';

export const TIER_CREATE_REQUEST = 'TIER_CREATE_REQUEST';
export const TIER_CREATE_SUCCESS = 'TIER_CREATE_SUCCESS';
export const TIER_CREATE_FAIL = 'TIER_CREATE_FAIL';

export const PAYMENT_PROGRAM_UPDATE_REQUEST = 'PAYMENT_PROGRAM_UPDATE_REQUEST';
export const PAYMENT_PROGRAM_UPDATE_SUCCESS = 'PAYMENT_PROGRAM_UPDATE_SUCCESS';
export const PAYMENT_PROGRAM_UPDATE_FAIL = 'PAYMENT_PROGRAM_UPDATE_FAIL';

export const TIERS_DELETE_REQUEST = 'TIERS_DELETE_REQUEST';
export const TIERS_DELETE_SUCCESS = 'TIERS_DELETE_SUCCESS';
export const TIERS_DELETE_FAIL = 'TIERS_DELETE_FAIL';

export const GET_PAYMENT_PROGRAM_REQUEST = 'GET_PAYMENT_PROGRAM_REQUEST';
export const GET_PAYMENT_PROGRAM_SUCCESS = 'GET_PAYMENT_PROGRAM_SUCCESS';
export const GET_PAYMENT_PROGRAM_FAIL = 'GET_PAYMENT_PROGRAM_FAIL';

export const TIERS_CLEAR_STATE = 'TIERS_CLEAR_STATE';

