/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./NewsSection.module.css";
import { Grid } from "@mui/material";
import Header from "../common/Header/Header";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import Icons from "../../assets/icons";
import CreateVideo from "./CreateNews/CreateVideo";
import CreateArticle from "./CreateNews/CreateArticle";
import { SaveChangesModal } from "../../components/index";
import ResponsePopup from "../ResponsePopup/ResponsePopup";
import { popup } from "../../utils/popup";

const NewsSection = () => {
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { getMe, getVideos, getPosts, deleteVideo, deletePost } = useActions();
  const { token } = useSelector((state) => state.admin);
  const { videos, posts } = useSelector((state) => state.news);
  const NEWS_PER_PAGE = "";
  const [page] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [isVideoTab, setIsVideoTab] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const [newsId, setNewsId] = useState(null);

  useEffect(() => {
    if (token) {
      if (isVideoTab) {
        getMe().then(() => getVideos(NEWS_PER_PAGE, page));
      } else {
        getMe().then(() => getPosts(NEWS_PER_PAGE, page));
      }
    }
  }, [token, page, isVideoTab]);

  const handleClick = () => {
    setIsActive(!isActive);
    setIsVideoTab(isActive);
  };

  const handleDeleteVideo = (id) => {
    deleteVideo(id).then(() => {
      getVideos(10, 1);
    });
  };

  const handleDeletePost = (id) => {
    deletePost(id).then(() => {
      getPosts(10, 1);
    });
  };

  const edit = (id) => {
    setNewsId(id);
    setIsOpenUpdate(true);
  };
  const create = () => {
    setIsOpenCreate(true);
  };

  const handleModalSuccessOpen = (text) => {
    setIsModalSuccessOpen(text);
  };

  const handleModalErrorOpen = (text) => {
    setIsModalErrorOpen(text);
  };

  return (
    <>
      <main className={styles.mainArea}>
        {isModalSuccessOpen && (
          <ResponsePopup
            text={isModalSuccessOpen}
            handleClose={() => setIsModalSuccessOpen(false)}
          />
        )}
        {isModalErrorOpen && (
          <ResponsePopup
            text={isModalErrorOpen}
            handleClose={() => setIsModalErrorOpen(false)}
          />
        )}
        <Grid container>
          <Header title="News" />
          <div className={styles.tabs}>
            <button
              disabled={isOpenCreate || isOpenUpdate}
              className={!isActive ? styles.active : ""}
              onClick={handleClick}
            >
              Videos
            </button>
            <button
              disabled={isOpenCreate || isOpenUpdate}
              className={isActive ? styles.active : ""}
              onClick={handleClick}
            >
              Articles
            </button>
          </div>
          {isVideoTab && (
            <>
              {!isOpenCreate && !isOpenUpdate && (
                <div className={styles.block}>
                  <div className={styles.videosContainer}>
                    {videos.map((video) => (
                      <div key={video.id} className={styles.wrapper}>
                        <div className={styles.wrap}>
                          <a href={video.link} target="_blank" rel="noreferrer">
                            <img
                              className={styles.image}
                              src={video.preview}
                              alt=""
                            />
                          </a>
                          <a href={video.link} target="_blank" rel="noreferrer">
                            <span className={styles.title}>{video.title}</span>
                          </a>
                        </div>
                        <div className={styles.buttons}>
                          <Icons.EditIcon onClick={() => edit(video.id)} />
                          <Icons.DeleteIcon
                            onClick={() => {
                              setDeleteItemId(video.id);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <button className={styles.addBtn} onClick={create}>
                    <Icons.PlusIcon onClick={create} />
                    Add new Video
                  </button>
                </div>
              )}
              {(isOpenCreate || isOpenUpdate) && (
                <CreateVideo
                  handleModalSuccessOpen={handleModalSuccessOpen}
                  handleModalErrorOpen={handleModalErrorOpen}
                  isOpenCreate={isOpenCreate}
                  isOpenUpdate={isOpenUpdate}
                  closeCreate={setIsOpenCreate}
                  closeUpdate={setIsOpenUpdate}
                  newsId={newsId}
                />
              )}
            </>
          )}
          {!isVideoTab && (
            <>
              {!isOpenCreate && !isOpenUpdate && (
                <div className={styles.block}>
                  <div className={styles.videosContainer}>
                    {posts.map((article) => (
                      <div key={article.id} className={styles.wrapper}>
                        <div className={styles.wrap}>
                          <img
                            className={styles.image}
                            src={article.image}
                            alt=""
                          />
                          <span className={styles.title}>{article.title}</span>
                        </div>
                        <div className={styles.buttons}>
                          <Icons.EditIcon onClick={() => edit(article.id)} />
                          <Icons.DeleteIcon
                            onClick={() => setDeleteItemId(article.id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <button className={styles.addBtn} onClick={create}>
                    <Icons.PlusIcon onClick={create} />
                    Create new Post
                  </button>
                </div>
              )}
              {(isOpenCreate || isOpenUpdate) && (
                <CreateArticle
                  handleModalSuccessOpen={handleModalSuccessOpen}
                  handleModalErrorOpen={handleModalErrorOpen}
                  isOpenCreate={isOpenCreate}
                  isOpenUpdate={isOpenUpdate}
                  closeCreate={setIsOpenCreate}
                  closeUpdate={setIsOpenUpdate}
                  newsId={newsId}
                  isVideoTab={isVideoTab}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  title={title}
                  description={description}
                />
              )}
            </>
          )}
        </Grid>
      </main>
      <SaveChangesModal
        isOpened={deleteItemId}
        closeModal={() => {
          setDeleteItemId(false);
        }}
        handleClose={() => {
          setDeleteItemId(false);
        }}
        handleSave={() => {
          isVideoTab
            ? handleDeleteVideo(deleteItemId)
            : handleDeletePost(deleteItemId);

          setDeleteItemId(null);
        }}
        save={() => {
          handleDeleteVideo(deleteItemId);
          setDeleteItemId(null);
        }}
        header={isVideoTab ? "Delete video" : "Delete article"}
        text={`Do you want to remove ${isVideoTab ? "video" : "article"}?`}
      />
    </>
  );
};

export default NewsSection;
