import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Icons from '../../assets/icons';
import { useActions } from '../../hooks/useActions';
import styles from './RestorePassword.module.css';

const loginSchema = yup.object().shape({
    newPassword: yup.string().min(4).max(16).required(),
});

const ChangePassword = () => {
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [token, setToken] = useState(null);
    const { changePassword } = useActions();
    const { error } = useSelector((state) => state.admin);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(loginSchema) });

    const onSubmit = (data) => {
        if (confirmPassword === data.newPassword) {
            localStorage.setItem('token', token)
            changePassword(data, token)
            .then(() => {
                if (!error) {
                    navigate('/login')
                }
            })
        }
    };
    useEffect(() => {
        const { search } = window.location;
        const token = new URLSearchParams(search).get('token');
        setToken(token);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftFrame}>
                <Icons.LoginLeftFrame />
            </div>
            <div className={styles.rightFrame}>
                <Icons.LoginRightFrame />
            </div>
            <div className={styles.logo}>
                <Icons.LoginLogo />
            </div>
            <div className={styles.box}>
                <p className={styles.greet}>Restore password</p>
                <p className={styles.title}>
                    To change the current password, enter the new password in both fields
                </p>

                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <label htmlFor='newPassword'>New Password</label>
                    <input
                        type='newPassword'
                        id='newPassword'
                        name='newPassword'
                        placeholder='Enter new password'
                        className={styles.passwordInput}
                        {...register('newPassword')}
                    />
                    {errors?.password?.message && (
                        <p className={styles.textHelper}>
                            {errors?.password?.message}
                        </p>
                    )}

                    <label htmlFor='password'>Confirm New Password</label>
                    <input
                        type='password'
                        id='password'
                        name='password'
                        placeholder='Confirm new password'
                        className={styles.passwordInput}
                        onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                    {errors?.password?.message && (
                        <p className={styles.textHelper}>
                            {errors?.password?.message}
                        </p>
                    )}

                    <input
                        type='submit'
                        value='Change Password'
                        readOnly
                        className={styles.submitButton}
                    />
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
