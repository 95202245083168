import React from 'react';
import styles from './CustomLink.module.css';
import { Link, useMatch } from 'react-router-dom';

const CustomLink = ({ children, to, icon, ...props }) => {
    const match = useMatch(to);

    return (
        <Link to={to} {...props}>
            <li className={match ? styles.active : styles.itemMenu}>
                {icon}
                <span>{children}</span>
            </li>
        </Link>
    );
};

export default CustomLink;
