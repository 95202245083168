/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './InventorySection.module.css';
import { Grid, CircularProgress } from '@mui/material';
import InventoryHeader from '../common/Header/Header';
import InventoryTable from './InventoryTable/InventoryTable';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';

const InventorySection = () => {
    const { token } = useSelector((state) => state.admin);
    const { inventories, count, loading } = useSelector((state) => state.inventory);
    const [page, setPage] = useState(1);
    const INVENTORY_PER_PAGE = 10;
    const { getInventory, getMe } = useActions();
    const [filter, setFilter] = useState('');
    const [debounce, setDebounce] = useState(null);

    const debounceSearch = (fn) => {
        clearTimeout(debounce)
        setDebounce(setTimeout(fn, 600))
    };
  
    const getFilteredInventory = (filter, loading) => {
        debounceSearch(() => {
          try {
            getInventory(INVENTORY_PER_PAGE, page, filter)
          } catch (e) {
            console.error(e)
          }
        })
    };

    useEffect(() => {
        setPage(1)
    }, [filter]);

    useEffect(() => {
        if (token) {
            getMe()
            .then(() => getFilteredInventory(filter))
        }
    }, [token, page, filter]);

    useEffect(() => {
        if (token) {
            getMe()
            .then(() => getInventory(INVENTORY_PER_PAGE, page))
        }
    }, [token, page]);

    return (
        <main className={styles.mainArea}>
            <Grid container>
                <InventoryHeader title="Inventory" search setFilter={setFilter} />
                {loading && <CircularProgress />}
                {!loading && (
                    <InventoryTable
                        inventories={inventories}
                        page={page}
                        setPage={setPage}
                        count={Math.ceil(count / INVENTORY_PER_PAGE)}
                    />
                )}
            </Grid>
        </main>
    );
};

export default InventorySection;
