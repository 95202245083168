import React from 'react';
import styles from './Sidebar.module.css';
import CustomLink from '../common/CustomLink/CustomLink';
import Icons from '../../assets/icons';
import { useActions } from '../../hooks/useActions';

const Sidebar = () => {
    const { logout } = useActions();
    return (
        <div className={styles.sidebar}>
            <div className={styles.logo}>
                <Icons.SidebarLogo />
            </div>
            <ul className={styles.listMenu}>
                <CustomLink to={'/statistics'} icon={<Icons.StatisticsIcon />}>
                    Statistics
                </CustomLink>
                <CustomLink to={'/users'} icon={<Icons.UsersIcon />}>
                    Users
                </CustomLink>
                <CustomLink to={'/payment'} icon={<Icons.PaymentWhiteIcon />}>
                    Payment programs
                </CustomLink>
                <CustomLink to={'/inventory'} icon={<Icons.InventoryNewIcon />}>
                    Inventory
                </CustomLink>
                <CustomLink to={'/news'} icon={<Icons.NewsIcon />}>
                    News
                </CustomLink>
                <CustomLink to={'/activity-history'} icon={<Icons.ActivityHistoryIcon />}>
                    Activity history
                </CustomLink>
            </ul>
            <Icons.SidebarFrame className={styles.img}/>
            <div className={styles.logout}>
                <div className={styles.logoutWrap} onClick={logout}>
                    <Icons.LogoutIcon />
                    <span>Log Out</span>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
