/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import styles from "./UserSection.module.css";
import { Grid } from "@mui/material";
import UsersHeader from "../UsersSection/UsersHeader/UsersHeader";
import Icons from "../../assets/icons";
import UsersInventory from "./UsersInventory/index";
import { useDispatch } from "react-redux";
import CustomTextInput from "../common/CustomTextInput/CustomTextInput";
import CondSection from "./CondSection";
import { DeleteUserModal, SaveChangesModal } from "../../components/index";
import Commission from "./Commission/index";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import ResponsePopup from "../../components/ResponsePopup/ResponsePopup";
import { popup } from "../../utils/popup";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export function useBlocker(blocker, when = true) {
  const { navigator } = React.useContext(NavigationContext);
  React.useEffect(() => {
    if (!when) return;
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

const createUserSchema = yup.object().shape({
  login: yup.string().min(1).max(25).required(),
  email: yup
    .string()
    .email("Invalid email format, should be format user@example.com")
    .required(),
  commission: yup.number(),
  // role: yup.number().required()
});

const UserSection = ({ view, edit }) => {
  const dispatch = useDispatch();
  const [permissionToNavigate, setPermissionToNavigate] = useState(false);
  const [routerPathName, setRouterPathName] = useState("/users");
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isChangeSmth, setIsChangeSmth] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const blocker = (tx) => {
    setRouterPathName(tx.location.pathname);
    if (permissionToNavigate) {
      tx.retry();
      setPermissionToNavigate(false);
    }
    openSaveModal();
  };
  useBlocker(blocker, !!edit && isChangeSmth);

  const navigate = useNavigate();
  const routeParams = useParams();
  const userId = routeParams.id;

  const { token, adminInfo } = useSelector((state) => state.admin);
  const { count, user } = useSelector((state) => state.user);
  const initials = `${adminInfo?.username?.[0].toUpperCase()}`;
  const { getUser, deleteUser, updateUser, getPayments, getTiers } =
    useActions();

  const [login, setLogin] = useState();
  const [commissionError, setCommissionError] = useState();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState();

  const [inventories, setInventories] = useState();
  const [runningLow, setRunningLow] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaveModalVisible, setSaveIsModalVisible] = useState(false);

  const [dateStartValue, setDateStartValue] = useState(null);

  const [selectedCommissionType, setSelectedCommissionType] = useState();
  const [selectedCommission, setSelectedCommission] = useState();
  const [nameSelectedCommissionType, setNameSelectedCommissionType] =
    useState();

  const [isUpdate, setIsUpdate] = useState(false);

  const commissionTypes = useSelector(
    (state) => state.statistics.paymentPrograms
  );
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createUserSchema),
    defaultValues: {
      login: login,
      password: password,
    },
  });

  useEffect(() => {
    reset({ login, email, password });
  }, [login, email]);

  const [totalSales, setTotalSales] = useState();
  const [toPay, setToPay] = useState();

  const [isOpenPaymentSection, setOpenPaymentSection] = useState(false);

  const [isClosed, setIsClosed] = useState(true);

  const handleOpenPaymentSection = () => {
    setOpenPaymentSection(!isOpenPaymentSection);
  };

  const closeDeleteModal = () => {
    setIsModalVisible(false);
  };
  const openDeleteModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (password) {
      if (password.length < 4) {
        setPasswordError("password must be at least 4 characters");
      } else if (password.length > 25) {
        setPasswordError("password must be at most 25 characters");
      } else {
        setPasswordError("");
      }
    }
  }, [password]);

  const closeSaveModal = () => {
    setSaveIsModalVisible(false);
  };
  const openSaveModal = () => {
    if (edit) {
      setSaveIsModalVisible(true);
    } else {
      navigate("/users");
    }
  };

  const handleDelete = () => {
    deleteUser(userId);
  };

  useEffect(() => {
    if (permissionToNavigate) {
      navigate(routerPathName);
    }
  }, [permissionToNavigate]);

  const handleClose = () => {
    setSaveIsModalVisible(false);
    setTimeout(() => {
      setPermissionToNavigate(true);
    });
  };
  const handleSave = () => {
    handleUpdate();
    setSaveIsModalVisible(false);
  };

  useEffect(() => {
    //setIsChangeSmth(true);
  }, [
    login,
    password,
    email,
    dateStartValue,
    selectedCommission,
    selectedCommissionType,
  ]);

  useEffect(() => {
    if (isUpdate) {
      setPermissionToNavigate(true);
      setTimeout(() => {
        navigate("/users");
      });
    }
  }, [user]);

  useEffect(() => {
    if (!selectedCommission) {
      setCommissionError("This is a required field");
      return;
    } else if (selectedCommission > 100) {
      setCommissionError("Сannot be greater than 100");
      return;
    } else {
      setCommissionError(false);
    }
  }, [selectedCommission]);

  const handleUpdate = (data) => {
    if (commissionError) return;
    if (passwordError) return;
    setIsUpdate(true);
    if (password !== "") {
      const payload = {
        username: login,
        email: email,
        password: password,
        roleId: user.roleId,
        paymentProgramId: selectedCommissionType,
        commission: Number(selectedCommission),
        period: dateStartValue,
      };
      setPermissionToNavigate(true);
      updateUser(userId, payload).then((data) => {});
    } else {
      const payload = {
        username: login,
        email: email,
        roleId: user.roleId,
        paymentProgramId: selectedCommissionType,
        commission: Number(selectedCommission),
        period: dateStartValue,
      };
      updateUser(userId, payload)
        .then((data) => {})
        .catch((error) => console.log(error));
      setPermissionToNavigate(true);
    }
  };

  const onCloseHandle = () => {
    setIsClosed(true);
  };

  const onOpenHandle = () => {
    setIsClosed(false);
  };
  useEffect(() => {
    if (token) {
      getUser(userId);
      getPayments();
      getTiers();
    }
    return () => {
      dispatch({ type: "CLEAR_ERROR" });
    };
  }, []);

  useEffect(() => {
    setLogin(user.username);
    setEmail(user.email);
    setValue("login", user.username);
    setValue("email", user.email);
    setSelectedCommission(user.commission);
    setTotalSales(user.sales);
    setInventories(user.inventories);
    setRunningLow(user.runningLow);
    setDateStartValue(user.period);
    setSelectedCommissionType(user.paymentProgramId);
    setToPay(user.toPay);

    const commissionTypeName = commissionTypes.find(
      (item) => item.id === user.paymentProgramId
    );
    setNameSelectedCommissionType(commissionTypeName?.value);
  }, [user]);

  const changesMiddleware = (func, args) => {
    func(args);
    setIsChangeSmth(true);
  };

  return (
    <main className={styles.mainArea}>
      {isModalSuccessOpen && (
        <ResponsePopup
          text={isModalSuccessOpen}
          handleClose={() => setIsModalSuccessOpen(false)}
        />
      )}
      {isModalErrorOpen && (
        <ResponsePopup
          text={isModalErrorOpen}
          handleClose={() => setIsModalErrorOpen(false)}
        />
      )}
      <Grid container>
        <UsersHeader totalCount={count} name={initials} />
        <div className={styles.block}>
          <div className={styles.header}>
            <div className={styles.name}>{user.username}</div>
            <Icons.CloseIcon
              onClick={
                isChangeSmth
                  ? openSaveModal
                  : () => {
                      navigate("/users");
                    }
              }
              className={styles.closeIcon}
            />
          </div>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <div className={styles.contentScroll}>
                <form className={styles.formContainer}>
                  <div className={styles.formRow}>
                    <div className={styles.formInfo}>
                      <Icons.FormLoginIcon className={styles.icons} />
                      <p>Login</p>
                    </div>
                    <div className={styles.inputColumn}>
                      <CustomTextInput
                        id="login"
                        name="login"
                        className={styles.input}
                        disable={view}
                        setIsChangeSmth={setIsChangeSmth}
                        additionalProps={{
                          value: login ? login : "",
                          onChange: (event) => {
                            setIsChangeSmth(true);
                            setLogin(event.target.value);
                          },
                        }}
                      />
                      {errors?.login?.message && (
                        <p className={styles.textHelper}>
                          {errors?.login?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.formRow}>
                    <div className={styles.formInfo}>
                      <Icons.FormPasswordIcon className={styles.icons} />
                      <p>Password</p>
                    </div>
                    <div className={styles.inputColumn}>
                      <CustomTextInput
                        id="password"
                        name="password"
                        type="password"
                        placeholder="********"
                        className={styles.input}
                        disable={view}
                        additionalProps={{
                          value: password ? password : "",
                          onChange: (event) =>
                            changesMiddleware(setPassword, event.target.value),
                        }}
                      />
                      {passwordError && (
                        <p className={styles.textHelper}>{passwordError}</p>
                      )}
                    </div>
                  </div>
                  <div className={styles.formRow}>
                    <div className={styles.formInfo}>
                      <Icons.FormEmailIcon className={styles.icons} />
                      <p>Email</p>
                    </div>
                    <div className={styles.inputColumn}>
                      <CustomTextInput
                        id="email"
                        name="email"
                        className={styles.input}
                        disable={view}
                        additionalProps={{
                          value: email ? email : "",
                          onChange: (event) => {
                            setIsChangeSmth(true);
                            setEmail(event.target.value);
                          },
                        }}
                      />
                      {errors?.email?.message && (
                        <p className={styles.textHelper}>
                          {errors?.email?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </form>
                <CondSection
                  commission={selectedCommission}
                  totalSales={totalSales}
                  toPay={toPay}
                />
                {view && (
                  <div className={styles.formRow}>
                    <div className={styles.selectWrap}>
                      <div className={styles.iconWrap}>
                        <Icons.FormPaymentIcon />
                        <div>Payment Programm</div>
                      </div>
                      <Icons.SelectFieldIcon
                        className={`${
                          isOpenPaymentSection && styles.activeArrow
                        }`}
                        onClick={handleOpenPaymentSection}
                      />
                    </div>
                    {isOpenPaymentSection && (
                      <div style={{ width: "100%" }}>
                        <Commission
                          selectedCommissionType={nameSelectedCommissionType}
                          selectedCommission={selectedCommission}
                          view
                          dateStartValue={dateStartValue}
                        />
                      </div>
                    )}
                  </div>
                )}
                {edit && (
                  <div className={styles.formRow}>
                    <div className={styles.selectWrap}>
                      <div className={styles.iconWrap}>
                        <Icons.FormPaymentIcon />
                        <div>Payment Programm</div>
                      </div>
                      <Icons.SelectFieldIcon
                        className={`${
                          isOpenPaymentSection && styles.activeArrow
                        }`}
                        onClick={handleOpenPaymentSection}
                      />
                    </div>
                    {isOpenPaymentSection && (
                      <div style={{ width: "100%" }}>
                        <Commission
                          commissionError={commissionError}
                          commissionTypes={commissionTypes}
                          selectedCommissionType={selectedCommissionType}
                          setSelectedCommissionType={setSelectedCommissionType}
                          selectedCommission={selectedCommission}
                          setSelectedCommission={(args) => {
                            changesMiddleware(setSelectedCommission, args);
                          }}
                          dateStartValue={dateStartValue}
                          setDateStartValue={(args) =>
                            changesMiddleware(setDateStartValue, args)
                          }
                          onCloseHandle={onCloseHandle}
                          onOpenHandle={onOpenHandle}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.buttons}>
                {/* <button className={`${styles.contentButton} ${view ? styles.disabledBtn : ''}`} disabled={view}>Edit</button> */}
                {edit && (
                  <button
                    className={`${styles.contentButton} ${
                      view ? styles.disabledBtn : ""
                    }`}
                    disabled={view}
                    onClick={handleSubmit(handleUpdate)}
                  >
                    Save
                  </button>
                )}
                <button
                  className={`${styles.contentButton}`}
                  onClick={openDeleteModal}
                >
                  Delete
                </button>
              </div>
            </div>
            <UsersInventory inventories={inventories} runningLow={runningLow} />
          </div>
        </div>
      </Grid>
      <DeleteUserModal
        isOpened={isModalVisible}
        closeModal={closeDeleteModal}
        deleteUser={handleDelete}
      />
      <SaveChangesModal
        isOpened={isSaveModalVisible}
        closeModal={closeSaveModal}
        handleClose={handleClose}
        handleUpdate={handleUpdate}
        handleSubmit={handleSubmit}
        header="Save changes before closing?"
        text="Your changes will be lost if you don’t save them"
      />
    </main>
  );
};

UserSection.propTypes = {
  view: PropTypes.bool,
  edit: PropTypes.bool,
};

export default UserSection;
