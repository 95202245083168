import React, { useEffect } from "react";
import styles from "./ResponsePopup.module.css";
import Alert from "@mui/material/Alert";

const ResponsePopup = ({ text, handleClose }) => {
  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 5000);
  }, []);

  return (
    <div className={styles.container}>
      <Alert
        onClose={() => {
          handleClose();
        }}
      >
        {text}
      </Alert>
    </div>
  );
};

export default ResponsePopup;
