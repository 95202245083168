/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./UsersSection.module.css";
import { Grid, CircularProgress } from "@mui/material";
// import UsersHeader from './UsersHeader/UsersHeader';
import UsersHeader from "../common/Header/Header";
import UsersTable from "./UsersTable/UsersTable";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import CreateUserModal from "../CreateUserModal/CreateUserModal";
import DeleteModal from "../DeleteUserModal/DeleteUserModal";
import ResponsePopup from "../../components/ResponsePopup/ResponsePopup";
import { useLocation } from "react-router-dom";
import { popup } from "../../utils/popup";

const UsersSection = ({ route }) => {
  const {
    getUsers,
    getMe,
    getPayments,
    getUserTypes,
    createUser,
    deleteUser,
    clearError,
    getTiers,
  } = useActions();
  const { token, adminInfo } = useSelector((state) => state.admin);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const { users, count, loading } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const USERS_PER_PAGE = 10;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => {
    setIsModalVisible(false);
    clearError();
  };
  const openModal = () => {
    setIsModalVisible(true);
  };

  const [userId, setUserId] = useState();

  const handleDelete = () => {
    deleteUser(userId).then(() => getUsers(10, 1));
  };

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };
  const openDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };

  const createNewUser = (data) => {
    getMe()
      .then(() => createUser(data))
      .then(() => {
        popup("User created", "success");
        closeModal();
        getUsers(USERS_PER_PAGE, page);
      })
      .catch((error) => {
        popup("User cannot be created", "error");
      });
  };

  const initials = `${adminInfo?.username?.[0].toUpperCase()}`;

  const [filter, setFilter] = useState("");
  const [debounce, setDebounce] = useState(null);

  const debounceSearch = (fn) => {
    clearTimeout(debounce);
    setDebounce(setTimeout(fn, 600));
  };

  const getFilteredUsers = (filter, loading) => {
    debounceSearch(() => {
      try {
        getMe().then(() => getUsers(USERS_PER_PAGE, page, filter));
      } catch (e) {
        console.error(e);
      }
    });
  };

  useEffect(() => {
    if (token) {
      getMe().then(() => getFilteredUsers(filter));
    }
  }, [token, page, filter]);

  useEffect(() => {
    if (token) {
      getMe().then(() => {
        getPayments();
        getUserTypes();
        getTiers();
      });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getMe().then(() => getUsers(USERS_PER_PAGE, page));
    }
  }, [token, page]);

  return (
    <main className={styles.mainArea}>
      {isModalSuccessOpen && (
        <ResponsePopup
          text={isModalSuccessOpen}
          handleClose={() => setIsModalSuccessOpen(false)}
        />
      )}
      {isModalErrorOpen && (
        <ResponsePopup
          text={isModalErrorOpen}
          handleClose={() => setIsModalErrorOpen(false)}
        />
      )}
      <Grid container>
        <UsersHeader
          totalCount={count}
          name={initials}
          search
          setFilter={setFilter}
          title={`List of Users (${count})`}
        />
        {loading && <CircularProgress />}
        {!loading && (
          <UsersTable
            users={users}
            page={page}
            setPage={setPage}
            count={Math.ceil(count / USERS_PER_PAGE)}
            createUser={openModal}
            setIsOpenDeleteModal={openDeleteModal}
            setUserId={setUserId}
          />
        )}
      </Grid>
      <CreateUserModal
        isOpened={isModalVisible}
        closeModal={closeModal}
        createUser={createNewUser}
      />
      <DeleteModal
        deleteUser={handleDelete}
        isOpened={isOpenDeleteModal}
        closeModal={closeDeleteModal}
        userId={userId}
      />
    </main>
  );
};

export default UsersSection;
