// import styles from './FileUloader.module.css';
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./FileUploader.module.css";
import Icons from "../../../assets/icons";
import { SaveChangesModal } from "../../../components/index";

const FileUploader = ({
  files,
  setFiles,
  image,
  name,
  setIsChangeSmth,
  setImage,
}) => {
  const [isDeleteImageModal, setIsDeleteImageModal] = useState(false);
  const [existImage, setExistImage] = useState(image);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    if (image) {
      setExistImage(image);
    }
  }, [image]);

  const acceptedFiles = files.map((file) => (
    <li key={file.path} className={styles.fileName}>
      {file.path}
      <div style={{ width: "100%", textAlign: "center" }}>
        <Icons.DeleteIcon
          onClick={() => {
            setIsDeleteImageModal(true);
          }}
        />
      </div>
    </li>
  ));

  const imageContainer = (
    <div className={styles.thumbWrap}>
      <img className={styles.thumb} alt="" src={image} />
    </div>
  );

  const imageName = (
    <div className={styles.fileName}>
      <div>{name}</div>
      <div style={{ width: "100%", textAlign: "center", cursor: "pointer" }}>
        <Icons.DeleteIcon
          onClick={() => {
            setIsDeleteImageModal(true);
          }}
        />
      </div>
    </div>
  );

  const thumbs = files.map((file) => (
    <div className={styles.thumbWrap} key={file.name}>
      <img
        className={styles.thumb}
        alt=""
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />
    </div>
  ));
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      <section className={styles.container}>
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          <Icons.UploadIcon />
          <p className={styles.text}>
            Drag and Drop here
            <br />
            (or click to find on your computer)
          </p>
        </div>
        <aside>
          <ul>{acceptedFiles}</ul>
        </aside>
        <aside>{thumbs}</aside>
        <aside>{existImage && imageContainer}</aside>
        <aside>{existImage && imageName}</aside>
      </section>
      <SaveChangesModal
        isOpened={isDeleteImageModal}
        closeModal={() => {
          setIsDeleteImageModal(false);
        }}
        handleClose={() => {
          setIsDeleteImageModal(false);
        }}
        handleSave={() => {
          setIsChangeSmth(true);
          setFiles([]);
          setExistImage(null);
          setImage(null);
          setIsDeleteImageModal(false);
        }}
        save={() => {
          setIsChangeSmth(true);
          setFiles([]);
          setExistImage(null);
          setImage(null);
          setIsDeleteImageModal(false);
        }}
        header="Delete picture"
        text={"Do you want to remove picture?"}
      />
    </>
  );
};

export default FileUploader;
