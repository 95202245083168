import { ToastContainer, toast } from "react-toastify";

export function popup(text, type) {
  toast(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: false,
    theme: "colored",
    type: type,
  });
}
