import {
    ADMIN_GET_ME_SUCCESS,
    ADMIN_LOGIN_FAIL,
    ADMIN_LOGIN_REQUEST,
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGOUT,
    ADMIN_RESET_PASSWORD_REQUEST,
    ADMIN_RESET_PASSWORD_SUCCESS,
    ADMIN_RESET_PASSWORD_FAIL,
    ADMIN_CHANGE_PASSWORD_REQUEST,
    ADMIN_CHANGE_PASSWORD_SUCCESS,
    ADMIN_CHANGE_PASSWORD_FAIL,
    ADMIN_SEND_CODE_REQUEST,
    ADMIN_SEND_CODE_SUCCESS,
    ADMIN_SEND_CODE_FAIL
} from '../constants/adminConstants';

const defaultState = {
    loading: false,
    adminInfo: {
        firstName: 'Unknown',
        lastName: 'Unknown',
    },
    error: null,
    token: null,
};

export const adminReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADMIN_LOGIN_REQUEST:
            return { ...state, loading: true };
        case ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                adminInfo: action.payload,
                token: action.payload.accessToken,
            };
        case ADMIN_GET_ME_SUCCESS:
            return { ...state, adminInfo: action.payload };
        case ADMIN_LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                token: null,
            };
        case ADMIN_LOGOUT:
            return { ...state, token: null };

        case ADMIN_RESET_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case ADMIN_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADMIN_RESET_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ADMIN_CHANGE_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case ADMIN_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                adminInfo: action.payload,
            };
        case ADMIN_CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case ADMIN_SEND_CODE_REQUEST:
            return { ...state, loading: true };
        case ADMIN_SEND_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADMIN_SEND_CODE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
