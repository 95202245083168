import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import Table from './Table';
import Tabs from './Tabs';

const UsersInventory = ({ inventories, runningLow }) => {
    const [tabInventory, setTabInventory] = useState(true);

    return (
        <div className={styles.block}>
            <h1>Inventory</h1>
            <Tabs value={tabInventory} setTabValue={setTabInventory} />
            {tabInventory &&
                <Table inventories={inventories} />
            }
            {!tabInventory &&
                <Table inventories={runningLow} />
            }
            
        </div>
    );
};

UsersInventory.propTypes = {
    inventories: PropTypes.array,
    runningLow: PropTypes.array,
};

export default UsersInventory;
