import PropTypes from "prop-types";
import styles from "./CustomTextInput.module.css";

const CustomTextInput = ({
  id,
  name,
  placeholder = "",
  type,
  additionalProps,
  className,
  disable,
  setIsChangeSmth,
}) => {
  return (
    <input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      className={`${styles.textInput} ${className}`}
      {...additionalProps}
      disabled={disable}
    />
  );
};

CustomTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "password", "number"]),
  placeholder: PropTypes.string,
  additionalProps: PropTypes.any,
  className: PropTypes.any,
  disable: PropTypes.bool,
};

export default CustomTextInput;
