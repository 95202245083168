import {
    STATISTIC_GET_REQUEST,
    STATISTIC_GET_SUCCESS,
    STATISTIC_GET_FAIL,
    STATISTIC_CLEAR_STATE,
    ACTIVITY_HISTORY_GET_REQUEST,
    ACTIVITY_HISTORY_GET_SUCCESS,
    ACTIVITY_HISTORY_GET_FAIL,
    TIERS_GET_REQUEST,
    TIERS_GET_SUCCESS,
    TIERS_GET_FAIL,
    TIER_CREATE_REQUEST,
    TIER_CREATE_SUCCESS,
    TIER_CREATE_FAIL,
    PAYMENT_PROGRAM_UPDATE_REQUEST,
    PAYMENT_PROGRAM_UPDATE_SUCCESS,
    PAYMENT_PROGRAM_UPDATE_FAIL,
    TIERS_DELETE_REQUEST,
    TIERS_DELETE_SUCCESS,
    TIERS_DELETE_FAIL,
    GET_PAYMENT_PROGRAM_REQUEST,
    GET_PAYMENT_PROGRAM_SUCCESS,
    GET_PAYMENT_PROGRAM_FAIL,
    TIERS_CLEAR_STATE
} from '../constants/statisticConstants';

const defaultState = {
    status: null,
    statistics: {},
    error: null,
    loading: true,
    activityHistory: [],
    tiers: [],
    tier: {},
    paymentProgram: {},
    paymentPrograms: [],
    count: 0,
};

export const statisticReducer = (state = defaultState, action) => {
    switch (action.type) {
        case STATISTIC_GET_REQUEST:
            return { ...state, loading: true };
        case STATISTIC_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                statistics: action.payload
            }
        case STATISTIC_GET_FAIL:
            return { ...state, loading: true, error: action.payload, };
        case STATISTIC_CLEAR_STATE:
            return { ...state, statistics: {}};

        case TIERS_CLEAR_STATE:
            return { ...state, tiers: [], tier: {}};

        case ACTIVITY_HISTORY_GET_REQUEST:
            return { ...state, loading: true };
        case ACTIVITY_HISTORY_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                activityHistory: action.payload.rows,
                count: action.payload.count,
            }
        case ACTIVITY_HISTORY_GET_FAIL:
            return { ...state, loading: true, error: action.payload, };

        case TIERS_GET_REQUEST:
            return { ...state, loading: true, error: null };
        case TIERS_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                tiers: action.payload,
            }
        case TIERS_GET_FAIL:
            return { ...state, loading: true, error: action.payload };

        case TIER_CREATE_REQUEST:
            return { ...state, loading: true, error: null };
        case TIER_CREATE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                tier: action.payload,
                status: action.status
            };
        case TIER_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case PAYMENT_PROGRAM_UPDATE_REQUEST:
            return { ...state, loading: true };
        case PAYMENT_PROGRAM_UPDATE_SUCCESS:
            return { 
                ...state,
                loading: false,
                paymentProgram: action.payload
            };
        case PAYMENT_PROGRAM_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case TIERS_DELETE_REQUEST:
            return { ...state, loading: true };
        case TIERS_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                tiers: [],
        };
        case TIERS_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case GET_PAYMENT_PROGRAM_REQUEST:
            return { ...state, loading: true };
        case GET_PAYMENT_PROGRAM_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentPrograms: action.payload,
            };
        case GET_PAYMENT_PROGRAM_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
        }
};
