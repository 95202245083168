import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import 'react-contexify/dist/ReactContexify.css';
import StatisticPagination from './Pagination/Pagination';
import styles from './ClientsTable.module.css';

const ClientsTable = ({ setPage, page, setSelectedUser }) => {
    const { users, count } = useSelector((state) => state.user);
    const USERS_PER_PAGE = 10;

    return (
        <Grid item xs={12}>
            <table className={styles.table}>
                <tbody className={styles.tableBody}>
                    {users?.map((item) => (
                        <tr
                            key={item.id}
                            id={item.id}
                        >
                            <td onClick={() => setSelectedUser(item)}>{item.username}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={styles.paginationArea}>
                <StatisticPagination setPage={setPage} page={page} count={Math.ceil(count / USERS_PER_PAGE)} />
            </div>
        </Grid>
    );
};

ClientsTable.propTypes = {
    setPage: PropTypes.func,
    page: PropTypes.number,
    count: PropTypes.number,
    setSelectedUser: PropTypes.func
};

export default ClientsTable;
