import React from 'react';
import { Grid, IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import styles from './UsersHeader.module.css';

const UsersHeader = ({ totalCount, name, search, setFilter }) => {

    return (
        <Grid item xs={12}>
            <div className={styles.wrapper}>
                <div>
                    <p
                        className={styles.title}
                    >{`List of Users (${totalCount})`}</p>
                </div>
                {search && 
                    <div className={styles.searchArea}>
                        <Paper
                            component='form'
                            sx={{
                                p: '2px 4px',
                                display: 'flex',
                                alignItems: 'center',
                                width: 295,
                            }}
                        >
                            <IconButton
                                type='button'
                                sx={{ p: '10px' }}
                                aria-label='search'
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                sx={{
                                    ml: 1,
                                    flex: 1,
                                    backgroundColor: 'transparent',
                                }}
                                placeholder='Search..'
                                inputProps={{ 'aria-label': 'search google maps' }}
                                onChange={(event) => setFilter(event.target.value)}
                            />
                        </Paper>
                        {/* <div onClick={logout} className={styles.logoutButton}>
                            <Avatar
                                sx={{
                                    backgroundColor: '#EB977A',
                                    margin: '0 5px 0 44px',
                                }}
                            >
                                {name}
                            </Avatar>
                        </div> */}
                    </div>
                }
            </div>
        </Grid>
    );
};

UsersHeader.propTypes = {
    totalCount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    search: PropTypes.bool
};

export default UsersHeader;
