import {
  USER_GET_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USERS_GET_FAIL,
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USER_GET_TYPE_FAIL,
  USER_GET_TYPE_REQUEST,
  USER_GET_TYPE_SUCCESS,
  USER_GET_REQUEST,
  USER_GET_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  CLEAR_ERROR,
} from "../constants/userConstants";
import { get, post, deleteOne, put } from "../../services/fetchData";
import { popup } from "../../utils/popup";

export const getUsers =
  (limit, page, filter = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: USERS_GET_REQUEST });
      const { data } = await get(
        `/users?limit=${limit}&page=${page}&search=${filter}`
      );
      dispatch({ type: USERS_GET_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: USERS_GET_FAIL, payload: error.message });
    }
  };

export const getUserTypes = () => async (dispatch) => {
  try {
    dispatch({ type: USER_GET_TYPE_REQUEST });
    const { data } = await get("/roles/user");
    dispatch({ type: USER_GET_TYPE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_GET_TYPE_FAIL, payload: error.message });
  }
};

export const createUser = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_CREATE_REQUEST });
    const res = await post("/users", formData);
    dispatch({ type: USER_CREATE_SUCCESS, payload: res.data });
  } catch (error) {
    const message = error.response.data.message;
    dispatch({ type: USER_CREATE_FAIL, payload: message });
    throw message;
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_GET_REQUEST });
    await get(`/users/${id}`).then((res) =>
      dispatch({ type: USER_GET_SUCCESS, payload: res.data })
    );
  } catch (error) {
    dispatch({ type: USER_GET_FAIL, payload: error.message });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DELETE_REQUEST });
    const { data } = await deleteOne(`/users/${id}`);
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_DELETE_FAIL, payload: error.message });
  }
};

export const updateUser = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });
    const { data } = await put(`/users/${id}`, formData);
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    popup("User updated", "success");
  } catch (error) {
    dispatch({ type: USER_UPDATE_FAIL, payload: error.message });
    popup("User cannot be updated", "error");
  }
};

export const clearError = () => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
