import { combineReducers } from 'redux';
import { adminReducer } from './adminReducer';
import { inventoryReducer } from './inventoryReducer';
import { userReducer } from './userReducer';
import { statisticReducer } from './statisticReducer';
import { newsReducer } from './newsReducer';

export const rootReducer = combineReducers({
    admin: adminReducer,
    user: userReducer,
    inventory: inventoryReducer,
    statistics: statisticReducer,
    news: newsReducer,
});
