/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./PaymentSection.module.css";
import { Grid } from "@mui/material";
import Header from "../common/Header/Header";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import EditMarginComModal from "./EditMarginComModal/EditMarginComModal";
import ResponsePopup from "../../components/ResponsePopup/ResponsePopup";

const PaymentSection = () => {
  const { getMe, getPayments } = useActions();
  const { token, adminInfo } = useSelector((state) => state.admin);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [header, setHeader] = useState("");
  const paymentSectionTitle = "Payment programms";
  const initials = `${adminInfo?.username?.[0].toUpperCase()}`;
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [value, setValue] = useState();
  const [commissionType, setCommissionType] = useState();

  const [marginCom, setMarginCom] = useState();
  const [revenueCom, setRevenueCom] = useState();
  const [tieredCom, setTieredCom] = useState();

  const { paymentPrograms } = useSelector((state) => state.statistics);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModal = (type) => {
    setCommissionType(type);
    checkCommissionType(type);
    setIsOpenModal(true);
  };

  const checkCommissionType = (type) => {
    switch (type) {
      case "Margin":
        setHeader("Gross Margin Commission Model");
        setValue(marginCom);
        return;
      case "Revenue":
        setHeader("Gross Revenue Commission Model");
        setValue(revenueCom);
        return;
      case "Tiered":
        setHeader("Tiered Commission");
        setValue(tieredCom);
        return;
      default:
        return type;
    }
  };

  const setPayments = (item) => {
    switch (item.value) {
      case "Gross Margin Commission":
        setMarginCom(item);
        return;
      case "Gross Revenue Commission":
        setRevenueCom(item);
        return;
      case "Tiered Commission":
        setTieredCom(item);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    paymentPrograms.forEach((item) => setPayments(item));
  }, [paymentPrograms]);

  useEffect(() => {
    if (token) {
      getMe().then(() => getPayments());
    }
  }, [token]);

  return (
    <main className={styles.mainArea}>
      {isModalSuccessOpen && (
        <ResponsePopup
          text={isModalSuccessOpen}
          handleClose={() => setIsModalSuccessOpen(false)}
        />
      )}
      {isModalErrorOpen && (
        <ResponsePopup
          text={isModalErrorOpen}
          handleClose={() => setIsModalErrorOpen(false)}
        />
      )}
      <Grid container>
        <Header title={paymentSectionTitle} name={initials} />
        <div className={styles.block}>
          <div className={styles.textBlock}>
            <div className={styles.wrap}>
              <h2>1. Gross Margin Commission Model</h2>
              <button
                className={styles.btn}
                onClick={() => openModal("Margin")}
              >
                Edit program
              </button>
            </div>
            <br />
            <p>
              The model is built on getting a percentage of the sales margin.
              The seller is not paid for the total amount of transactions, but a
              percentage of their margins
            </p>
            <br />
            <p>
              For example:
              <br />A seller bought $100 worth of merchandise in stock. Sold
              them for $150. The revenue was $50. The superadministrator set the
              percentage equal to 10%. This way the seller will be paid $50 *
              10% = $5
            </p>
          </div>
          <div className={styles.textBlock}>
            <div className={styles.wrap}>
              <h2>2. Gross Revenue Commission Model</h2>
              <button
                className={styles.btn}
                onClick={() => openModal("Revenue")}
              >
                Edit program
              </button>
            </div>
            <br />
            <p>
              The model is based on getting a percentage of the seller's total
              trades.
            </p>
            <br />
            <p>
              For example:
              <br />A salesman bought $100 worth of merchandise from the
              warehouse. Sold them for $150. The total amount of all sales is
              $150. The superadministrator has set a percentage equal to 10%.
              Therefore, the seller will be paid $150 * 10% = $15.
            </p>
          </div>
          <div className={styles.textBlock}>
            <div className={styles.wrap}>
              <h2>3. Tiered Commission</h2>
              <button
                className={styles.btn}
                onClick={() => openModal("Tiered")}
              >
                Edit program
              </button>
            </div>
            <br />
            <p>
              Representative at tier 1 ($1.00-$4,999.00) is at a 10% commission
              rate for each sale. A month in she then reaches tier 2
              ($5,000-10,000.00), now all sales commissions for the entire sales
              period/quarter are paid at 12.5% commission - essentially making
              it so that all sales made from $1.00 to current sales to date are
              now paid out at a 12.5% commission rate.
            </p>
          </div>
        </div>
        <EditMarginComModal
          handleModalSuccessOpen={setIsModalSuccessOpen}
          handleModalErrorOpen={setIsModalSuccessOpen}
          isOpened={isOpenModal}
          closeModal={closeModal}
          header={header}
          type={commissionType}
          value={value}
          setValue={setValue}
        />
      </Grid>
    </main>
  );
};

export default PaymentSection;
