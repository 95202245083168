/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Icons from "../../../assets/icons";
import styles from "./ClientsTab.module.css";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import ClientsTable from "./ClientsTable/ClientsTable";
import StatisticTable from "../StatisticTable/StatisticTable";
import Overview from "../Overview/Overview";
import DateFilter from "../DateFilter/DateFilter";

const ClientsTab = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const { getUsers, getMe, getStatisticClients } = useActions();

  const { token } = useSelector((state) => state.admin);
  const { statistics } = useSelector((state) => state.statistics);

  const [filter, setFilter] = useState("");
  const [selectedUser, setSelectedUser] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [page, setPage] = useState(1);
  const [debounce, setDebounce] = useState(null);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const USERS_PER_PAGE = 10;
  const PER_PAGE = 10;

  const clearFilter = () => {
    setSelectedUser(false);
  };

  const debounceSearch = (fn) => {
    clearTimeout(debounce);
    setDebounce(setTimeout(fn, 600));
  };

  const getFilteredUsers = (filter, loading) => {
    debounceSearch(() => {
      try {
        getUsers(USERS_PER_PAGE, page, filter);
      } catch (e) {
        console.error(e);
      }
    });
  };

  useEffect(() => {
    if (token) {
      getMe().then(() => getFilteredUsers(filter));
    }
  }, [token, page, filter]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  useEffect(() => {
    if (token) {
      getMe().then(() => getUsers(USERS_PER_PAGE, page));
    }
  }, [token, page]);

  useEffect(() => {
    if (token && selectedUser) {
      if (startDate && endDate) {
        if (selectedCategory) {
          const payload = {
            userId: selectedUser.id,
            limit: PER_PAGE,
            page: page,
            type: selectedCategory.value,
            endDate: endDate,
            startDate: startDate,
          };

          getMe().then(() => getStatisticClients(payload));
        } else {
          const payload = {
            userId: selectedUser.id,
            limit: PER_PAGE,
            page: page,
            type: "",
            endDate: endDate,
            startDate: startDate,
          };

          getMe().then(() => getStatisticClients(payload));
        }
      } else {
        if (selectedCategory) {
          const payload = {
            userId: selectedUser.id,
            limit: PER_PAGE,
            page: page,
            type: selectedCategory.value,
            endDate: "",
            startDate: "",
          };

          getMe().then(() => getStatisticClients(payload));
        } else {
          const payload = {
            userId: selectedUser.id,
            limit: PER_PAGE,
            page: page,
            type: "",
            endDate: "",
            startDate: "",
          };

          getMe().then(() => getStatisticClients(payload));
        }
      }
    }
  }, [startDate, endDate, selectedCategory, page, selectedUser]);

  return (
    <div className={styles.container}>
      {!selectedUser && (
        <>
          <div className={styles.searchArea}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  backgroundColor: "transparent",
                }}
                placeholder="Search.."
                inputProps={{ "aria-label": "search google maps" }}
                onChange={(event) => setFilter(event.target.value)}
              />
            </Paper>
          </div>
          <ClientsTable
            page={page}
            setPage={setPage}
            setSelectedUser={setSelectedUser}
          />
        </>
      )}
      {selectedUser && (
        <div className={styles.blockWrap}>
          <div className={styles.header}>
            {selectedUser.username}
            <Icons.CloseIcon
              onClick={clearFilter}
              className={styles.closeIcon}
            />
          </div>

          <div className={styles.blockArea}>
            <Overview
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <StatisticTable
              startDate={startDate}
              endDate={endDate}
              className={styles.activeArrow}
              inventories={statistics?.soldInventories?.rows}
              setIsOpenDatePicker={setIsOpenDatePicker}
              isOpenDatePicker={isOpenDatePicker}
              page={page}
              setPage={setPage}
              count={Math.ceil(statistics?.soldInventories?.count / PER_PAGE)}
            />
          </div>
          {isOpenDatePicker && (
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              className={styles.dateFilter}
              setIsOpenDatePicker={setIsOpenDatePicker}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          )}
        </div>
      )}
    </div>
  );
};

ClientsTab.propTypes = {};

export default ClientsTab;
