import React from 'react';
import styles from './styles.module.css';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function BasicDatePicker({ 
  value,
  setValue,
  views,
  disabled,
  className,
  disableOpenPicker,
  minDate,
  maxDate,
  inputFormat,
  onClose,
  onOpen,
  openTo
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} style={{width: '100%'}}>
      <DatePicker
        disableOpenPicker={disableOpenPicker}
        disabled={disabled}
        views={views}
        inputFormat={inputFormat ? inputFormat : "DD/MM/YYYY"}
        label=""
        value={value}
        onChange={(newValue) => {
          setValue(dayjs(newValue).valueOf());
        }}
        className={`${styles.datePicker} ${className}`}
        renderInput={(params) => <TextField {...params} sx={{
          '.MuiInputBase-input': {width: '100%', color: '#717171'},
        }}/>}
        minDate={minDate}
        maxDate={maxDate}
        onClose={onClose}
        onOpen={onOpen}
        openTo={openTo}
      />
    </LocalizationProvider>
  );
}

