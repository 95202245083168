import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Pagination, PaginationItem } from "@mui/material";
import { Stack } from "@mui/system";
import PropTypes from "prop-types";

import styles from "./UsersInventoryPagination.module.css";

const PreviousButton = () => {
  return (
    <div className={styles.previousButton}>
      <ArrowBackIosIcon className={styles.previousIcon} />
    </div>
  );
};

const NextButton = () => {
  return (
    <div className={styles.nextButton}>
      <ArrowForwardIosIcon className={styles.nextIcon} />
    </div>
  );
};

const UsersInventoryPagination = ({ setPage, page, count }) => {
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Stack spacing={2}>
      <Pagination
        count={count}
        size="small"
        onChange={handleChange}
        page={page}
        renderItem={(item) => (
          <PaginationItem
            components={{
              previous: PreviousButton,
              next: NextButton,
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#FFC3AB",
                color: "#FFFFFF",
              },
              fontFamily: "Montserrat",
              fontWeight: 600,
              сolor: "#6D6D6D",
            }}
            {...item}
          />
        )}
      />
    </Stack>
  );
};

UsersInventoryPagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default UsersInventoryPagination;
