import React from 'react';
import PropTypes from 'prop-types';
import styles from '../EditMarginComModal.module.css';

const TierTab = ({ 
    item,
    index,
    setActiveTier,
    isActive,
    ...rest 
}) => {
    return (
        <div 
            className={`${styles.tab} ${isActive && styles.active}`}
            key={item.id}
            {...rest}
        >{`Tier ${index + 1}`}</div>
    );
};

TierTab.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number, 
    setActiveTier: PropTypes.func, 
    isActive: PropTypes.bool
};

export default TierTab;
