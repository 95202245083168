export const VIDEOS_GET_REQUEST = "VIDEOS_GET_REQUEST";
export const VIDEOS_GET_SUCCESS = "VIDEOS_GET_SUCCESS";
export const VIDEOS_GET_FAIL = "VIDEOS_GET_FAIL";

export const VIDEO_GET_REQUEST = "VIDEO_GET_REQUEST";
export const VIDEO_GET_SUCCESS = "VIDEO_GET_SUCCESS";
export const VIDEO_GET_FAIL = "VIDEO_GET_FAIL";

export const VIDEO_CREATE_REQUEST = "VIDEO_CREATE_REQUEST";
export const VIDEO_CREATE_SUCCESS = "VIDEO_CREATE_SUCCESS";
export const VIDEO_CREATE_FAIL = "VIDEO_CREATE_FAIL";

export const VIDEO_UPDATE_REQUEST = "VIDEO_UPDATE_REQUEST";
export const VIDEO_UPDATE_SUCCESS = "VIDEO_UPDATE_SUCCESS";
export const VIDEO_UPDATE_FAIL = "VIDEO_UPDATE_FAIL";

export const VIDEO_DELETE_REQUEST = "VIDEO_DELETE_REQUEST";
export const VIDEO_DELETE_SUCCESS = "VIDEO_DELETE_SUCCESS";
export const VIDEO_DELETE_FAIL = "VIDEO_DELETE_FAIL";

export const POSTS_GET_REQUEST = "POSTS_GET_REQUEST";
export const POST_CLEAN = "POST_CLEAN";
export const POSTS_GET_SUCCESS = "POSTS_GET_SUCCESS";
export const POSTS_GET_FAIL = "POSTS_GET_FAIL";

export const POST_GET_REQUEST = "POST_GET_REQUEST";
export const POST_GET_SUCCESS = "POST_GET_SUCCESS";
export const POST_GET_FAIL = "POST_GET_FAIL";

export const POST_CREATE_REQUEST = "POST_CREATE_REQUEST";
export const POST_CREATE_SUCCESS = "POST_CREATE_SUCCESS";
export const POST_CREATE_FAIL = "POST_CREATE_FAIL";

export const POST_UPDATE_REQUEST = "POST_UPDATE_REQUEST";
export const POST_UPDATE_SUCCESS = "POST_UPDATE_SUCCESS";
export const POST_UPDATE_FAIL = "POST_UPDATE_FAIL";

export const POST_DELETE_REQUEST = "POST_DELETE_REQUEST";
export const POST_DELETE_SUCCESS = "POST_DELETE_SUCCESS";
export const POST_DELETE_FAIL = "POST_DELETE_FAIL";
