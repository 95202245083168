import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material/";
import dayjs from "dayjs";
import Icons from "../../assets/icons";
import styles from "./CreateUserModal.module.css";
import CustomTextInput from "../common/CustomTextInput/CustomTextInput";
import { useForm } from "react-hook-form";
import CustomSelect from "../common/CustomSelect/CustomSelect";
import { useSelector } from "react-redux";
import DatePicker from "../common/DatePicker/DatePicker";

const createUserSchema = yup.object().shape({
  username: yup.string().min(1).max(25).required(),
  password: yup.string().min(4).max(25).required(),
  email: yup
    .string()
    .email("Invalid email format, should be format user@example.com")
    .required(),
  commission: yup.number(),
  // role: yup.number().required()
});

const CreateUserModal = ({ isOpened, closeModal, createUser }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(createUserSchema) });

  const { paymentPrograms, tiers } = useSelector((state) => state.statistics);
  const { userTypes, error } = useSelector((state) => state.user);

  const [dateStartValue, setDateStartValue] = useState(
    dayjs(new Date()).valueOf()
  );
  const [dateEndValue, setDateEndValue] = useState(dayjs(new Date()).valueOf());

  const [paymentProgram, setPaymentProgram] = useState();
  const [role, setRole] = useState();
  const [commission, setCommission] = useState();
  const [disableCommissionField, setDisableCommissionField] = useState(false);

  useEffect(() => {
    if (!isOpened) {
      reset();
      setDateStartValue(dayjs(new Date()).valueOf());
      setDateEndValue(
        dayjs(new Date().getTime() + 1000 * 60 * 60 * 24 * 30).valueOf()
      );
      setRole(null);
    }
  }, [isOpened, reset]);

  useEffect(() => {
    setPaymentProgram(paymentPrograms[0]?.id);
    setCommission(paymentPrograms[0]?.commission);

    if (paymentPrograms[0]?.id === 3) {
      setDisableCommissionField(true);
    }
  }, [paymentPrograms]);

  useEffect(() => {
    setRole(userTypes[0]?.id);
  }, [isOpened, userTypes]);

  const onSubmit = (data) => {
    if (data && role && paymentProgram && dateStartValue && commission) {
      createUser({
        ...data,
        roleId: role,
        paymentProgramId: paymentProgram,
        period: dateStartValue,
        commission: Number(commission),
      });
    }
  };

  const setSelectedPaymentProgram = (value) => {
    setPaymentProgram(value);
    if (value === 3) {
      setDisableCommissionField(true);
    } else {
      setDisableCommissionField(false);
    }
    const foundCommission = paymentPrograms.find((item) => item.id === value);
    setCommission(foundCommission?.commission);
  };

  return (
    <div className={styles.container}>
      <Modal open={isOpened} onClose={closeModal}>
        <div className={styles.mainContainer}>
          <div className={styles.header}>
            <p>Create a New User Account</p>
            <Icons.CloseIcon
              onClick={closeModal}
              className={styles.closeIcon}
            />
          </div>
          <form
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={styles.formRow}
              style={{
                alignItems: errors?.login?.message ? "baseline" : "center",
              }}
            >
              <div className={styles.formInfo}>
                <Icons.FormLoginIcon />
                <p>Login</p>
              </div>
              <div className={styles.inputColumn}>
                <CustomTextInput
                  id="login"
                  name="login"
                  onChange={(event) => {}}
                  additionalProps={{ ...register("username") }}
                />
                {errors?.username?.message && (
                  <p className={styles.textHelper}>
                    {errors?.username?.message}
                  </p>
                )}
              </div>
            </div>

            <div
              className={styles.formRow}
              style={{
                alignItems: errors?.password?.message ? "baseline" : "center",
              }}
            >
              <div className={styles.formInfo}>
                <Icons.FormPasswordIcon />
                <p>Password</p>
              </div>
              <div className={styles.inputColumn}>
                <CustomTextInput
                  id="password"
                  name="password"
                  type="password"
                  additionalProps={{ ...register("password") }}
                />
                {errors?.password?.message && (
                  <p className={styles.textHelper}>
                    {errors?.password?.message}
                  </p>
                )}
              </div>
            </div>
            <div
              className={styles.formRow}
              style={{
                alignItems: errors?.email?.message ? "baseline" : "center",
              }}
            >
              <div className={styles.formInfo}>
                <Icons.FormEmailIcon />
                <p>Email</p>
              </div>
              <div className={styles.inputColumn}>
                <CustomTextInput
                  id="email"
                  name="email"
                  additionalProps={{ ...register("email") }}
                />
                {errors?.email?.message && (
                  <p className={styles.textHelper}>{errors?.email?.message}</p>
                )}
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formInfo}>
                <Icons.FormUserTypeIcon />
                <p>User Type</p>
              </div>
              <div className={styles.inputColumn}>
                <CustomSelect
                  name="role"
                  options={userTypes}
                  additionalProps={{
                    value: role,
                    onChange: (event) => setRole(event.target.value),
                  }}
                />
                {!role && (
                  <p className={styles.textHelper}>field is required</p>
                )}
              </div>
            </div>
            <div className={styles.title}>
              <p>Payment program</p>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formInfo}>
                <Icons.PayIcon className={styles.icon} />
                <p>Commission type</p>
              </div>
              <div className={styles.inputColumn}>
                <CustomSelect
                  name="paymentProgramId"
                  options={paymentPrograms}
                  additionalProps={{
                    value: paymentProgram,
                    onChange: (event) => {
                      setSelectedPaymentProgram(event.target.value);
                    },
                  }}
                />
                {errors?.paymentProgramId?.message && (
                  <p className={styles.textHelper}>
                    {errors?.paymentProgramId?.message}
                  </p>
                )}
              </div>
            </div>
            {paymentProgram !== 3 ? (
              <div className={styles.formRow}>
                <div className={styles.formInfo}>
                  <Icons.PercentageIcon className={styles.icon} />
                  <p>Commission</p>
                </div>
                <div className={styles.inputColumn}>
                  <CustomTextInput
                    id="selectedCommission"
                    name="selectedCommission"
                    type="number"
                    className={styles.select}
                    disable={disableCommissionField}
                    additionalProps={{
                      value: commission,
                      onChange: (event) => {
                        setCommission(event.target.value);
                      },
                    }}
                  />
                  {errors?.commission?.message && (
                    <p className={styles.textHelper}>
                      {errors?.commission?.message}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              tiers.map((tier, index) => (
                <div className={styles.formRow}>
                  <div className={styles.formInfo}>
                    <Icons.PercentageIcon className={styles.icon} />
                    <p>Tier {index + 1} commission</p>
                  </div>
                  <div className={styles.inputColumn}>
                    <CustomTextInput
                      id="selectedCommission"
                      name="selectedCommission"
                      type="number"
                      className={styles.select}
                      disable={true}
                      additionalProps={{
                        value: tier.commission,
                      }}
                    />
                  </div>
                </div>
              ))
            )}
            <div className={styles.formRow}>
              <div className={styles.wrap}>
                <div className={styles.formInfoDate}>
                  <Icons.CalendarIcon className={styles.icon} />
                  <p>Period</p>
                </div>
                <DatePicker
                  value={dateStartValue}
                  setValue={setDateStartValue}
                  views={["year", "month"]}
                  inputFormat="MM/YYYY"
                  openTo="month"
                />
              </div>
            </div>
            {!dateStartValue && (
              <p className={styles.textHelper}>field is required</p>
            )}
            {/* {dateStartValue > dateEndValue && (
                            <p className={styles.textHelper}>
                                Start date cannot be after the end date
                            </p>
                        )}
                        <div className={styles.formRow}>
                            <div className={styles.wrap}>
                                <div className={styles.formInfoDate}>
                                    <Icons.CalendarIcon className={styles.icon} />
                                    <p>End date</p>
                                </div>
                                <DatePicker 
                                    value={dateEndValue}
                                    setValue={setDateEndValue}
                                    minDate={dateStartValue}
                                    views={['year', 'month']}
                                    inputFormat="MM/YYYY"
                                />
                            </div>
                        </div>
                        {!dateEndValue && (
                            <p className={styles.textHelper}>
                                field is required
                            </p>
                        )}
                        {dateEndValue < dateStartValue && (
                            <p className={styles.textHelper}>
                                End date cannot be before the start date
                            </p>
                        )} */}
            {error && <p className={styles.textHelper}>{error}</p>}
            <input
              type="submit"
              value="Save"
              readOnly
              className={styles.saveButton}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
};

CreateUserModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
};

export default CreateUserModal;
