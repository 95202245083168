import {
    INVENTORY_GET_REQUEST,
    INVENTORY_GET_SUCCESS,
    INVENTORY_GET_FAIL,
    INVENTORY_TYPES_GET_REQUEST,
    INVENTORY_TYPES_GET_SUCCESS,
    INVENTORY_TYPES_GET_FAIL
} from '../constants/inventoryConstants';

const defaultState = {
    inventories: [],
    count: 0,
    error: null,
    loading: true,
    inventoryTypes: []
};

export const inventoryReducer = (state = defaultState, action) => {
    switch (action.type) {
        case INVENTORY_GET_REQUEST:
            return { ...state, loading: true };
        case INVENTORY_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                inventories: action.payload.rows,
                count: action.payload.count,
            }
        case INVENTORY_GET_FAIL:
            return { ...state, loading: true, error: action.payload, };

        case INVENTORY_TYPES_GET_REQUEST:
            return { ...state, loading: true };
        case INVENTORY_TYPES_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                inventoryTypes: action.payload,
            }
        case INVENTORY_TYPES_GET_FAIL:
            return { ...state, loading: true, error: action.payload, };
        default:
            return state;
    }
};
