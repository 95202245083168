import React from 'react';
import { Menu, Item } from 'react-contexify';
import { useNavigate } from 'react-router-dom';

import Icons from '../../../../assets/icons';
import styles from './UsersContextMenu.module.css';
import './contextify.css';

const UsersContextMenu = ({setIsOpenDeleteModal, setUserId}) => {
    const navigate = useNavigate();

    const handleClick = ({ event, props }) => {
        const id = props.id;

        switch (event.currentTarget.id) {
            case 'view': {
                navigate(`/users/${id}`);
                return;
            }

            case 'edit': {
                navigate(`/users/${id}/edit`);
                return;
            }

            case 'delete': {
                setUserId(id)
                setIsOpenDeleteModal()
                return;
            }

            default:
                return;
        }
    };

    return (
        <Menu id='contextMenu'>
            <Item id='view' onClick={handleClick}>
                <div className={styles.contextItem}>
                    <Icons.ContextViewProfileIcon className={styles.itemIcon} />
                    <p>View Profile</p>
                </div>
            </Item>
            <Item id='edit' onClick={handleClick}>
                <div className={styles.contextItem}>
                    <Icons.ContextEditIcon className={styles.itemIcon} />
                    <p>Edit</p>
                </div>
            </Item>
            <Item id='delete' onClick={handleClick}>
                <div className={styles.contextItem}>
                    <Icons.ContextDeleteIcon className={styles.itemIcon} />
                    <p>Delete</p>
                </div>
            </Item>
        </Menu>
    );
};

export default UsersContextMenu;
