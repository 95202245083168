/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./CreateNews.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Icons from "../../../assets/icons";
import { SaveChangesModal } from "../../../components/index";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { popup } from "../../../utils/popup";

export function useBlocker(blocker, when = true) {
  const { navigator } = React.useContext(NavigationContext);
  React.useEffect(() => {
    if (!when) return;
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

const CreateVideo = ({
  isOpenCreate,
  isOpenUpdate,
  closeCreate,
  closeUpdate,
  newsId,
  handleModalSuccessOpen,
  handleModalErrorOpen,
}) => {
  const [permissionToNavigate, setPermissionToNavigate] = useState(false);
  const [routerPathName, setRouterPathName] = useState("/news");
  const [isChangeSmth, setIsChangeSmth] = useState(false);
  const blocker = (tx) => {
    setRouterPathName(tx.location.pathname);
    if (permissionToNavigate) {
      tx.retry();
      setPermissionToNavigate(false);
    }
    setSaveIsModalVisible(true);
  };
  useBlocker(blocker, isChangeSmth);

  const changesMiddleware = (func, args) => {
    func(args);
    setIsChangeSmth(true);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (permissionToNavigate) {
      setTitle("");
      setLink("");
      if (routerPathName !== "/news") {
        navigate(routerPathName);
      } else {
        closeCreate(false);
        closeUpdate(false);
      }
    }
  }, [permissionToNavigate]);

  const { video } = useSelector((state) => state.news);
  const [isSaveModalVisible, setSaveIsModalVisible] = useState(false);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const { getMe, getVideos, getVideo, updateVideo, createVideo } = useActions();
  const { token } = useSelector((state) => state.admin);

  const [error, setError] = useState(null);

  const closeSaveModal = () => {
    setSaveIsModalVisible(false);
    setTimeout(() => {
      setPermissionToNavigate(true);
    });
  };
  const closeForm = () => {
    if (title.length === 0 && link.length === 0 && !isOpenUpdate) {
      setSaveIsModalVisible(false);
      handleClose();
    } else {
      setSaveIsModalVisible(true);
    }
  };
  const handleClose = () => {
    if (isOpenCreate) {
      setLink("");
      setTitle("");
      //closeCreate(false);
    } else if (isOpenUpdate) {
      //closeUpdate(false);
    }
    setTimeout(() => {
      setPermissionToNavigate(true);
    });
  };
  const handleSave = () => {
    setSaveIsModalVisible(false);
    if (isOpenCreate) {
      const payload = {
        name: title,
        title: title,
        link: link,
      };
      if (title.length > 0 && link.length > 0) {
        createVideo(payload).then((data) => {
          getVideos(10, 1);
        });
        setTitle("");
        setLink("");
        setError(null);
        //closeCreate(false);
        setPermissionToNavigate(true);
      } else {
        setError("Field is required");
      }
    } else if (isOpenUpdate) {
      const payload = {
        name: title,
        title: title,
        link: link,
      };
      if (title.length > 0 && link.length > 0) {
        updateVideo(newsId, payload).then(() => {
          getVideos(10, 1);
        });
        setError(null);
        setPermissionToNavigate(true);
      } else {
        setError("Field is required");
      }
    }
  };

  useEffect(() => {
    if (token) {
      if (isOpenUpdate) {
        getMe().then(() => getVideo(newsId));
      }
    }
  }, [token]);

  useEffect(() => {
    if (token && isOpenUpdate) {
      setLink(video?.link);
      setTitle(video?.title);
    }
  }, [video]);

  useEffect(() => {
    return () => {
      setLink("");
      setTitle("");
    };
  }, []);

  return (
    <>
      <div className={styles.block}>
        <div className={styles.header}>
          <span>{isOpenCreate ? "Upload a new video" : "Edit video"}</span>
          <Icons.CloseIcon
            onClick={
              isChangeSmth
                ? closeForm
                : () => {
                    closeCreate(false);
                    closeUpdate(false);
                  }
            }
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.wrapper}>
          <div>
            <div className={styles.wrap}>
              <span className={styles.title}>Title</span>
              <input
                className={styles.inputTitle}
                value={title}
                onChange={(event) =>
                  changesMiddleware(setTitle, event.target.value)
                }
              />
              {error && title.length === 0 && (
                <p className={styles.textHelper}>{error}</p>
              )}
            </div>
            <div className={styles.wrap}>
              <span className={styles.title}>Video URL</span>
              <input
                placeholder="URL here"
                className={styles.inputTitle}
                value={link}
                onChange={(event) =>
                  changesMiddleware(setLink, event.target.value)
                }
              />
              {error && link.length === 0 && (
                <p className={styles.textHelper}>{error}</p>
              )}
            </div>
          </div>
          <button className={styles.btn} onClick={handleSave}>
            {isOpenCreate ? "Publish" : "Save"}
          </button>
        </div>
      </div>
      <SaveChangesModal
        isOpened={isSaveModalVisible}
        closeModal={closeSaveModal}
        handleClose={handleClose}
        handleSave={handleSave}
        save={handleSave}
        header="Unsaved changes"
        text={
          isOpenCreate
            ? "Do you want to publish the video before leaving this page?"
            : "Do you want to save your changes before leaving this page?"
        }
      />
    </>
  );
};

CreateVideo.propTypes = {
  isOpenCreate: PropTypes.func,
  isOpenUpdate: PropTypes.func,
  closeCreate: PropTypes.func,
  closeUpdate: PropTypes.func,
  newsId: PropTypes.number,
};

export default CreateVideo;
