import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { useActions } from '../../hooks/useActions';
import Icons from '../../assets/icons';
import styles from './Login.module.css';

const loginSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().min(4).max(16).required(),
});

const Login = () => {
    const { token, error } = useSelector((state) => state.admin);
    const { login } = useActions();
    const [errorMessage, setErrorMessage]= useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(loginSchema) });

    const onSubmit = (data) => {
        login(data);
    };

    const isAuthorized = token !== null;

    useEffect(() => {
        setErrorMessage(error)
    }, [error])


    return (
        <>
            {!isAuthorized && (
                <>
                    <div className={styles.wrapper}>
                        <div className={styles.leftFrame}>
                            <Icons.LoginLeftFrame />
                        </div>
                        <div className={styles.rightFrame}>
                            <Icons.LoginRightFrame />
                        </div>
                        <div className={styles.logo}>
                            <Icons.LoginLogo />
                        </div>
                        <div className={styles.box}>
                            <p className={styles.greet}>Welcome back!</p>
                            <p className={styles.title}>
                                Please enter your details
                            </p>

                            <form
                                className={styles.form}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <label htmlFor='login'>Login</label>
                                <input
                                    type='text'
                                    id='login'
                                    name='firstname'
                                    placeholder='Enter your login'
                                    className={styles.textInput}
                                    {...register('username')}
                                />
                                {errors?.username?.message && (
                                    <p className={styles.textHelper}>
                                        {errors?.username?.message}
                                    </p>
                                )}

                                <label htmlFor='password'>Password</label>
                                <input
                                    type='password'
                                    id='password'
                                    name='password'
                                    placeholder='Enter your password'
                                    className={styles.passwordInput}
                                    {...register('password')}
                                />
                                {errors?.password?.message && (
                                    <p className={styles.textHelper}>
                                        {errors?.password?.message}
                                    </p>
                                )}
                                {errorMessage && (
                                    <p className={styles.textHelper}>
                                        {errorMessage}
                                    </p>
                                )}
                                <input
                                    type='submit'
                                    value='Log in'
                                    readOnly
                                    className={styles.submitButton}
                                />

                                <a href='/restorepassword' className={styles.restore}>
                                    Restore password
                                </a>
                                {/* <p className={styles.optional}>
                                    Don’t have an account?&nbsp;
                                    <a href='/signup' className={styles.restore}>
                                        Sign up for free
                                    </a>
                                </p> */}
                            </form>
                        </div>
                    </div>
                </>
            )}
            {isAuthorized && <Navigate to='/users' />}
        </>
    );
};

export default Login;
