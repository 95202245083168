/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Icons from "../../../../assets/icons";
import styles from "../EditMarginComModal.module.css";
import CustomTextInput from "../../../common/CustomTextInput/CustomTextInput";

const Tier = ({ tierProp, setNewTier }) => {
  const [startingSum, setStartingSum] = useState("");
  const [endingSum, setEndingSum] = useState("");
  const [commission, setCommission] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageObj, setErrorMessageObj] = useState({
    start: "",
    end: "",
  });

  const [isFocused, setIsFocused] = useState({
    commission: false,
    start: false,
    end: false,
  });

  const checkValidTierSums = (startSum, endSum) => {
    const requiredFiendErrorMsg = "Field is required";
    const notLessThanOneErrorMsg =
      "Ending sum or starting sum should not be less than 1";
    const notStartSumMoreThanEndSumErrorMsg =
      "Ending sum should be more than starting sum";

    let isNotError = true;
    const numStartSum = Number(startSum);
    const numEndSum = Number(endSum);

    setErrorMessageObj({ start: "", end: "" });

    if (startSum === "" || endSum === "") {
      handleSetErrorMessage(startSum === "", !endSum, requiredFiendErrorMsg);
      isNotError = false;
    }

    if ((startSum && startSum < 0) || (endSum && endSum < 1)) {
      handleSetErrorMessage(
        startSum && startSum < 0,
        endSum && endSum < 1,
        notLessThanOneErrorMsg
      );
      isNotError = false;
    }

    if (numStartSum >= numEndSum && isFocused.start && isFocused.end) {
      handleSetErrorMessage(
        false,
        numStartSum >= numEndSum,
        notStartSumMoreThanEndSumErrorMsg
      );
      isNotError = false;
    }

    return isNotError;
  };

  const handleSetErrorMessage = (startError, endError, msg) => {
    setErrorMessageObj((prev) => ({
      start: startError ? msg : prev.start,
      end: endError ? msg : prev.end,
    }));
  };

  const handleSetCommissionErrorMessage = (value, msg) => {
    setErrorMessage((prev) => (value ? msg : prev));
  };
  const checkValidCommission = (value) => {
    setErrorMessage("");
    let isNotError = true;
    if (value === "") {
      handleSetCommissionErrorMessage(true, "Field is required");
      isNotError = false;
    }
    if ((value || value === 0) && value < 1) {
      handleSetCommissionErrorMessage(
        Number(value) < 1,
        "Commission should not be less than 1"
      );
      isNotError = false;
    }
    if (value && Number(value) > 100) {
      handleSetCommissionErrorMessage(
        value,
        "Commission should not be more than 100%"
      );
      isNotError = false;
    }
    return isNotError;
  };

  useEffect(() => {
    setErrorMessage("");
    if (tierProp) {
      setStartingSum(tierProp.startingSum);
      setEndingSum(tierProp.endingSum);
      setCommission(tierProp.commission);
      if (tierProp.id === "new") {
        setIsDisabled(false);
        setStartingSum("");
        setEndingSum("");
        setCommission("");
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
      setStartingSum("");
      setEndingSum("");
      setCommission("");
    }
  }, [tierProp]);

  useEffect(() => {
    const isNotError = checkValidTierSums(startingSum, endingSum);
    const isNotCommissionError = checkValidCommission(commission);

    if (isNotError && isNotCommissionError) {
      if (startingSum !== "" && endingSum && commission) {
        const payload = {
          commission: Number(commission),
          startingSum: Number(startingSum),
          endingSum: Number(endingSum),
        };
        setNewTier(payload);
      } else {
        setNewTier(null);
      }
    } else {
      setNewTier(null);
    }
  }, [startingSum, endingSum, commission]);

  return (
    <>
      <div className={styles.formRow}>
        <div className={styles.formInfo}>
          <Icons.TierIcon className={styles.icon} />
          <p>Tier</p>
        </div>
        <CustomTextInput
          id="selectedCommission"
          name="selectedCommission"
          disable
          additionalProps={{
            value: `${startingSum} - ${endingSum} USD`,
            // onChange: (event) => setSelectedCommission(event.target.value)
          }}
        />
      </div>
      <div className={styles.formRow}>
        <div className={styles.formInfo}>
          <Icons.PercentageIcon className={styles.icon} />
          <p>Commission</p>
        </div>
        <div className={styles.inputColumn}>
          <CustomTextInput
            id="selectedCommission"
            type="number"
            name="selectedCommission"
            className={styles.input}
            disable={isDisabled}
            additionalProps={{
              value: commission,
              onChange: (event) => setCommission(event.target.value),
              onBlur: (event) => setCommission(Math.round(Number(commission))),
              onFocus: () =>
                setIsFocused((prev) => ({
                  ...prev,
                  commission: true,
                })),
            }}
          />
          {errorMessage && isFocused.commission && (
            <p className={styles.textHelper}>{errorMessage}</p>
          )}
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.formInfo}>
          <Icons.DollarIcon className={styles.icon} />
          <p>Starting sum</p>
        </div>
        <div className={styles.inputColumn}>
          <CustomTextInput
            id="selectedCommission"
            type="number"
            name="selectedCommission"
            className={styles.input}
            disable={isDisabled}
            additionalProps={{
              value: startingSum,
              onChange: (event) => setStartingSum(event.target.value),
              onBlur: (event) => setStartingSum(Math.round(startingSum)),
              onFocus: () =>
                setIsFocused((prev) => ({
                  ...prev,
                  start: true,
                })),
            }}
          />
          {errorMessageObj.start && isFocused.start && (
            <p className={styles.textHelper}>{errorMessageObj.start}</p>
          )}
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.formInfo}>
          <Icons.DollarIcon className={styles.icon} />
          <p>Ending sum</p>
        </div>
        <div className={styles.inputColumn}>
          <CustomTextInput
            id="selectedCommission"
            type="number"
            name="selectedCommission"
            className={styles.input}
            disable={isDisabled}
            additionalProps={{
              value: endingSum,
              onChange: (event) => setEndingSum(event.target.value),
              onBlur: (event) => setEndingSum(Math.round(endingSum)),
              onFocus: () =>
                setIsFocused((prev) => ({
                  ...prev,
                  end: true,
                })),
            }}
          />
          {errorMessageObj.end && isFocused.end && (
            <p className={styles.textHelper}>{errorMessageObj.end}</p>
          )}
        </div>
      </div>
    </>
  );
};

Tier.propTypes = {
  tierProp: PropTypes.object,
  setNewTier: PropTypes.func,
};

export default Tier;
