/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./StatisticSection.module.css";
import { Grid } from "@mui/material";
import StatisticHeader from "../common/Header/Header";
import ClientsTab from "./ClientsTab/ClientsTab";
import ProductsTab from "./ProductsTab/ProductsTab";

const StatisticSection = () => {
  const [isActive, setIsActive] = useState(false);
  const [isProductsTab, setIsProductsTab] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  const handleClick = () => {
    setIsActive(!isActive);
    setIsProductsTab(isActive);
  };

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
  }, [isProductsTab]);

  return (
    <main className={styles.mainArea}>
      <Grid container>
        <StatisticHeader title="Statistics" />
        <div className={styles.tabs}>
          <button
            className={!isActive ? styles.active : ""}
            onClick={handleClick}
          >
            Products
          </button>
          <button
            className={isActive ? styles.active : ""}
            onClick={handleClick}
          >
            Clients
          </button>
        </div>
        {isProductsTab && (
          <ProductsTab
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        {!isProductsTab && (
          <ClientsTab
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        )}
      </Grid>
    </main>
  );
};

export default StatisticSection;
