import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import "react-contexify/dist/ReactContexify.css";
import ActivityHistoryPagination from "./ActivityHistoryPagination/ActivityHistoryPagination";
import styles from "./ActivityHistoryTable.module.css";
import DatePicker from "../../common/DatePicker/DatePicker";
import Icons from "../../../assets/icons";
import dayjs from "dayjs";
import { truncateWord } from "../../../utils/truncateWord";

const ActivityHistoryTable = ({
  history = [],
  setPage,
  page,
  count,
  date,
  setDate,
  setIsClosed,
}) => {
  const stock = (bool) => {
    if (bool) {
      return (
        <span className={styles.arrowWrap}>
          From stock <Icons.ArrowUpIcon />
        </span>
      );
    } else {
      return (
        <span className={styles.arrowWrap}>
          To stock <Icons.ArrowDownIcon />
        </span>
      );
    }
  };

  return (
    <Grid item xs={12}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableColumn}>
            <th>User</th>
            <th>Activity</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Date</th>
            <th>
             
            </th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {history?.map((item) => (
            <tr key={item.id} id={item.id}>
              <td>{item.username}</td>
              <td>{stock(item.fromStock)}</td>
              <td>{truncateWord(item.inventory)}</td>
              <td>{item.quantity}</td>
              <td>{`${dayjs(item.date).format("DD/MM/YYYY")}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.paginationArea}>
        <ActivityHistoryPagination
          setPage={setPage}
          page={page}
          count={count}
        />
      </div>
    </Grid>
  );
};

ActivityHistoryTable.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      fromStock: PropTypes.bool,
      date: PropTypes.string,
    })
  ).isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default ActivityHistoryTable;
