import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import "react-contexify/dist/ReactContexify.css";
import InventoryPagination from "./InventoryPagination/InventoryPagination";
import styles from "./InventoryTable.module.css";

const InventoryTable = ({ inventories = [], setPage, page, count }) => {
  return (
    <Grid item xs={12}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableColumn}>
            <th>Item</th>
            <th>Category</th>
            <th>Cost per item</th>
            <th>Price</th>
            <th>Profit</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {inventories?.map((item) => (
            <tr key={item.id} id={item.id}>
              <td>{item.name}</td>
              <td>{item.category}</td>
              <td>{`$${item.netCost}`}</td>
              <td>{`$${item.price}`}</td>
              <td>{`$${
                (item.price - item.netCost) % 1 !== 0
                  ? (item.price - item.netCost).toFixed(2)
                  : item.price - item.netCost
              }`}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.paginationArea}>
        <InventoryPagination setPage={setPage} page={page} count={count} />
      </div>
    </Grid>
  );
};

InventoryTable.propTypes = {
  inventories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default InventoryTable;
