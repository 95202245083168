/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useActions } from "../../../../hooks/useActions";
import styles from "../EditMarginComModal.module.css";
import Tier from "./Tier";
import TierTab from "./TierTab";
import { popup } from "../../../../utils/popup";

const TierLayout = () => {
  const { getMe, getTiers, createTier, deleteTiers, clearTiers } = useActions();
  const { token } = useSelector((state) => state.admin);
  const { tiers } = useSelector((state) => state.statistics);
  const [tiersState, setTiersState] = useState(tiers);
  const [selectedTier, setSelectedTier] = useState();
  const [newTier, setNewTier] = useState(null);
  const { error } = useSelector((state) => state.statistics);
  const [errorFromBack, setErrorFromBack] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledSave, setIsDisabledSave] = useState(false);

  const addNewTier = () => {
    if (tiersState.length < 3) {
      setIsDisabledSave(false);
      let newState = tiersState;

      if (tiersState.length === 1) {
        newState.push({
          id: "new",
          commission: "",
          startingSum: "",
          endingSum: "",
        });
        setTiersState(newState);
        setSelectedTier(tiersState[1]);
        setIsDisabled(true);
        setIsDisabledSave(false);
      } else {
        newState.push({
          id: "new",
          commission: "",
          startingSum: "",
          endingSum: "",
        });
        setTiersState(newState);
        setSelectedTier(tiersState[2]);
        setIsDisabled(true);
        setIsDisabledSave(false);
      }
    } else {
      setIsDisabled(true);
      setIsDisabledSave(false);
    }
  };

  const setActiveTier = (item) => {
    setSelectedTier(item);
  };

  const save = () => {
    if (newTier) {
      getMe().then(() => {
        createTier(newTier).then((status) => {
          if (status === 201) {
            popup("Tier created", "success");
            setIsDisabled(false);
            setIsDisabledSave(true);
            getTiers();
          }
        });
      });
    }
  };

  const deleteAllTiers = () => {
    getMe().then(() => {
      deleteTiers().then(() => {
        popup("All tiers remmoved", "success");
      });
      clearTiers();
      setSelectedTier({
        id: "new",
        commission: "",
        startingSum: "",
        endingSum: "",
      });
      setTiersState([]);
    });
  };

  useEffect(() => {
    if (token) {
      getMe().then(() => getTiers());
    }
  }, [token]);

  useEffect(() => {
    if (tiers.length === tiersState.length) {
      if (tiers.length === 0) {
        setIsDisabled(true);
        setIsDisabledSave(false);
      } else if (tiers.length === 3) {
        setIsDisabled(true);
        setIsDisabledSave(true);
      } else {
        setIsDisabled(false);
        setIsDisabledSave(true);
      }
    } else {
      setIsDisabled(true);
      setIsDisabledSave(false);
    }
  }, [tiers, tiersState]);

  useEffect(() => {
    setTiersState(tiers);
    setSelectedTier(tiers[0]);
  }, [tiers]);

  useEffect(() => {
    setErrorFromBack(error);
  }, [error]);

  useEffect(() => {
    if (selectedTier?.id === "new") {
      setIsDisabledSave(false);
    } else {
      setIsDisabledSave(true);
    }
  }, [selectedTier]);

  return (
    <div className={styles.formColumn}>
      <div className={styles.tabs}>
        {tiersState?.map((item, index) => (
          <TierTab
            key={item.id}
            item={item}
            index={index}
            onClick={() => setActiveTier(item)}
            isActive={selectedTier?.id === item.id}
          />
        ))}
      </div>
      <Tier tierProp={selectedTier} setNewTier={setNewTier} />
      {errorFromBack && <p className={styles.textHelper}>{errorFromBack}</p>}
      <div className={styles.buttons}>
        <button
          className={`${styles.addBtn} ${isDisabled && styles.disabled}`}
          onClick={addNewTier}
          disabled={isDisabled}
        >
          Add new Tier
        </button>
        <button
          className={`${styles.yesBtn} ${isDisabledSave && styles.disabled}`}
          onClick={() => save()}
          disabled={isDisabledSave}
        >
          Save
        </button>
        {tiers.length > 0 && (
          <button className={styles.deleteBtn} onClick={() => deleteAllTiers()}>
            Delete all
          </button>
        )}
      </div>
    </div>
  );
};

export default TierLayout;
