import React from "react";
import { Grid, IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import styles from "./Header.module.css";
import DatePicker from "../../common/DatePicker/DatePicker";

const Header = ({
  title,
  name,
  search,
  setFilter,
  activityHistory,
  dateValue,
  setDateValue,
  onCloseHandle,
  onOpenHandle,
}) => {
  return (
    <Grid item xs={12}>
      <div className={styles.wrapper}>
        <div>
          <p className={styles.title}>{title}</p>
        </div>

        <div style={{ display: "flex" }}>
          {activityHistory && (
            <div className={styles.datePickerWrap} style={{ marginRight: 20 }}>
              <DatePicker
                className={styles.datePicker}
                value={dateValue}
                setValue={setDateValue}
                views={["year", "month"]}
                inputFormat={"MM/YYYY"}
                onClose={onCloseHandle}
                onOpen={onOpenHandle}
                openTo="month"
              />
            </div>
          )}

          {search && (
            <div className={styles.searchArea}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 295,
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    backgroundColor: "transparent",
                  }}
                  placeholder="Search.."
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={(event) => setFilter(event.target.value)}
                />
              </Paper>
              {/* <div onClick={logout} className={styles.logoutButton}>
                            <Avatar
                                sx={{
                                    backgroundColor: '#EB977A',
                                    margin: '0 5px 0 44px',
                                }}
                            >
                                {name}
                            </Avatar>
                        </div> */}
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  search: PropTypes.bool,
};

export default Header;
