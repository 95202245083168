import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { useActions } from '../../hooks/useActions';
import Icons from '../../assets/icons';
import styles from './RestorePassword.module.css';
import VerifyCode from './VerifyCode';

const loginSchema = yup.object().shape({
    email: yup.string().required().email("Invalid email format, should be format user@example.com"),
});

const RestorePassword = () => {
    const { token, error } = useSelector((state) => state.admin);
    const { resetPassword } = useActions();
    const [isVerifyCode, setIsVerifyCode] = useState(false)
    const [errorMessage, setErrorMessage]= useState('');
    

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(loginSchema) });

    const onSubmit = (data) => {
        resetPassword(data).then(() => setIsVerifyCode(true))
    };

    const isAuthorized = token !== null;

    useEffect(() => {
        setErrorMessage(error)
    }, [error])

    return (
        <>
            {!isAuthorized && (
                <>
                    <div className={styles.wrapper}>
                        <div className={styles.leftFrame}>
                            <Icons.LoginLeftFrame />
                        </div>
                        <div className={styles.rightFrame}>
                            <Icons.LoginRightFrame />
                        </div>
                        <div className={styles.logo}>
                            <Icons.LoginLogo />
                        </div>
                        {!isVerifyCode &&
                            <div className={styles.box}>
                                <a href='/login'><Icons.BackIcon className={styles.backIcon} /></a>
                                <p className={styles.greet}>Restore password</p>
                                <p className={styles.title}>
                                    Enter your email address and we’ll send you a link to reset your password 
                                </p>

                                <form
                                    className={styles.form}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <label htmlFor='email'>Email address</label>
                                    <input
                                        type='text'
                                        id='email'
                                        name='firstname'
                                        placeholder='Enter your email'
                                        className={styles.textInput}
                                        {...register('email')}
                                    />
                                    {errors?.email?.message && (
                                        <p className={styles.textHelper}>
                                            {errors?.email?.message}
                                        </p>
                                    )}
                                    {errorMessage && (
                                        <p className={styles.textHelper}>
                                            {errorMessage}
                                        </p>
                                    )}
                                    <input
                                        type='submit'
                                        value='Send a link'
                                        readOnly
                                        className={styles.submitButton}
                                    />
                                </form>
                            </div>
                        }
                        {isVerifyCode && <VerifyCode />}
                    </div>
                </>
            )}
            {isAuthorized && <Navigate to='/' />}
        </>
    );
};

export default RestorePassword;
