import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { Sidebar } from '../../components/';
import styles from './Main.module.css';

const Main = () => {
    const { token } = useSelector((state) => state.admin);
    const isAuthorized = token !== null;

    return (
        <>
            {isAuthorized && (
                <div className={styles.app}>
                    <div className={styles.container}>
                        <Sidebar />
                        <Outlet />
                    </div>
                </div>
            )}

            {!isAuthorized && <Navigate to='/login' />}
        </>
    );
};

export default Main;
