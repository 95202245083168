import {
  STATISTIC_GET_REQUEST,
  STATISTIC_GET_SUCCESS,
  STATISTIC_GET_FAIL,
  STATISTIC_CLEAR_STATE,
  ACTIVITY_HISTORY_GET_REQUEST,
  ACTIVITY_HISTORY_GET_SUCCESS,
  ACTIVITY_HISTORY_GET_FAIL,
  TIERS_GET_REQUEST,
  TIERS_GET_SUCCESS,
  TIERS_GET_FAIL,
  TIER_CREATE_REQUEST,
  TIER_CREATE_SUCCESS,
  TIER_CREATE_FAIL,
  PAYMENT_PROGRAM_UPDATE_REQUEST,
  PAYMENT_PROGRAM_UPDATE_SUCCESS,
  PAYMENT_PROGRAM_UPDATE_FAIL,
  TIERS_DELETE_REQUEST,
  TIERS_DELETE_SUCCESS,
  TIERS_DELETE_FAIL,
  GET_PAYMENT_PROGRAM_REQUEST,
  GET_PAYMENT_PROGRAM_SUCCESS,
  GET_PAYMENT_PROGRAM_FAIL,
  TIERS_CLEAR_STATE,
} from "../constants/statisticConstants";
import { get, post, put, deleteAll } from "../../services/fetchData";

export const getStatisticProducts = (payload) => async (dispatch) => {
  try {
    dispatch({ type: STATISTIC_GET_REQUEST });
    const { data } = await get(
      `/statistics/products?type=${payload.type}&endDate=${payload.endDate}&startDate=${payload.startDate}&limit=${payload.limit}&page=${payload.page}`
    );
    dispatch({ type: STATISTIC_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: STATISTIC_GET_FAIL, payload: error.message });
  }
};

export const getStatisticClients = (payload) => async (dispatch) => {
  try {
    dispatch({ type: STATISTIC_GET_REQUEST });
    const { data } = await get(
      `/statistics/clients?userId=${payload.userId}&limit=${payload.limit}&page=${payload.page}&type=${payload.type}&endDate=${payload.endDate}&startDate=${payload.startDate}`
    );
    dispatch({ type: STATISTIC_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: STATISTIC_GET_FAIL, payload: error.message });
  }
};

export const clearStatistics = () => async (dispatch) => {
  dispatch({ type: STATISTIC_CLEAR_STATE });
};
export const clearTiers = () => async (dispatch) => {
  dispatch({ type: TIERS_CLEAR_STATE });
};

export const getActivityHistory = (payload) => async (dispatch) => {
  try {
    dispatch({ type: ACTIVITY_HISTORY_GET_REQUEST });
    const { data } = await get(
      `/inventory/activity-history?date=${payload.date}&limit=${payload.limit}&page=${payload.page}&search=${payload.search}`
    );
    dispatch({ type: ACTIVITY_HISTORY_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACTIVITY_HISTORY_GET_FAIL, payload: error.message });
  }
};

export const getTiers = () => async (dispatch) => {
  try {
    dispatch({ type: TIERS_GET_REQUEST });
    const { data, status } = await get(`/payment-program/tiers`);
    dispatch({ type: TIERS_GET_SUCCESS, payload: data, status: status });
  } catch (error) {
    dispatch({ type: TIERS_GET_FAIL, payload: error.message });
  }
};

export const createTier = (payload) => async (dispatch) => {
  try {
    dispatch({ type: TIER_CREATE_REQUEST });
    const { data, status } = await post(`/payment-program/tiers`, payload);
    dispatch({ type: TIER_CREATE_SUCCESS, payload: data, status: status });
    return status;
  } catch (error) {
    dispatch({ type: TIER_CREATE_FAIL, payload: error.response.data.message });
  }
};

export const deleteTiers = () => async (dispatch) => {
  try {
    dispatch({ type: TIERS_DELETE_REQUEST });
    const { data } = await deleteAll("/payment-program/tiers");
    dispatch({ type: TIERS_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: TIERS_DELETE_FAIL, payload: error.message });
  }
};

export const getPayments = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PAYMENT_PROGRAM_REQUEST });
    const { data } = await get("/payment-program");
    dispatch({ type: GET_PAYMENT_PROGRAM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_PAYMENT_PROGRAM_FAIL, payload: error.message });
  }
};
export const updatePayments = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_PROGRAM_UPDATE_REQUEST });
    const { data } = await put(`/payment-program/${id}`, payload);
    dispatch({ type: PAYMENT_PROGRAM_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PAYMENT_PROGRAM_UPDATE_FAIL, payload: error.message });
  }
};
