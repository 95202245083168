import React from 'react';
import { Grid, Avatar } from '@mui/material';
import PropTypes from 'prop-types';

import { useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import Icons from '../../../assets/icons';
import UsersPagination from './UsersPagination/UsersPagination';
import UsersContextMenu from './UsersContextMenu/UsersContextMenu';
import styles from './UsersTable.module.css';

const UsersTable = ({ 
    users = [],
    setPage,
    page, 
    count, 
    createUser, 
    setIsOpenDeleteModal, 
    setUserId 
}) => {
    const { show } = useContextMenu({
        id: 'contextMenu',
    });

    const displayContextMenu = (e) => {
        show(e, { props: { id: Number(e.currentTarget.id) } });
    };

    return (
        <Grid item xs={12}>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.tableColumn}>
                        <th>Name</th>
                        <th>Current sales</th>
                        <th>Current commission</th>
                        <th>
                            <div
                                className={styles.createUserButton}
                                onClick={createUser}
                            >
                                <Icons.CreateUserIcon />
                                <p>Create user</p>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.tableBody}>
                    {users?.map((user) => (
                        <tr
                            key={user.id}
                            id={user.id}
                            onContextMenu={displayContextMenu}
                        >
                            <td>
                                <div className={styles.userInfo}>
                                    <Avatar />
                                    {`${user?.username}`}
                                </div>
                            </td>
                            <td>{`${user.sales ? user.sales : 0} USD`}</td>
                            <td>{`${user.commission ? user.commission : 0} %`}</td>
                            <td><Icons.DotsMenu id={user.id} onClick={displayContextMenu}/></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={styles.paginationArea}>
                <UsersPagination setPage={setPage} page={page} count={count} />
            </div>
            <UsersContextMenu setIsOpenDeleteModal={setIsOpenDeleteModal} setUserId={setUserId} />
        </Grid>
    );
};

UsersTable.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            username: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        })
    ).isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    createUser: PropTypes.func.isRequired,
    setIsOpenDeleteModal: PropTypes.func,
    setUserId: PropTypes.func
};

export default UsersTable;
