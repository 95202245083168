import {
    ADMIN_GET_ME_FAIL,
    ADMIN_GET_ME_REQUEST,
    ADMIN_GET_ME_SUCCESS,
    ADMIN_LOGIN_FAIL,
    ADMIN_LOGIN_REQUEST,
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGOUT,
    ADMIN_RESET_PASSWORD_REQUEST,
    ADMIN_RESET_PASSWORD_SUCCESS,
    ADMIN_RESET_PASSWORD_FAIL,
    ADMIN_CHANGE_PASSWORD_REQUEST,
    ADMIN_CHANGE_PASSWORD_SUCCESS,
    ADMIN_CHANGE_PASSWORD_FAIL,
    ADMIN_SEND_CODE_REQUEST,
    ADMIN_SEND_CODE_SUCCESS,
    ADMIN_SEND_CODE_FAIL
} from '../constants/adminConstants';
import { get, post, patch } from '../../services/fetchData';
import { errorHandler } from '../../utils/errorHandler';

export const login = (admin) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_LOGIN_REQUEST });
        const { data } = await post('/auth/login', admin);
        dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: data });
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('refresh_token', data.refreshToken);
    } catch (error) {
        dispatch({ type: ADMIN_LOGIN_FAIL, payload: errorHandler(error) });
    }
};

export const getMe = () => async (dispatch) => {

    const refreshToken = async () => {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            await post('/auth/generateNewTokens', {refreshToken: refreshToken})
            .then((res) => {
                localStorage.setItem('token', res.data.accessToken);
                localStorage.setItem('refresh_token', res.data.refreshToken);
            })
            .catch(e => {
                console.log('ERROR AUTH')
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                window.location.href = '/login'
            });
        } else {
          console.log('no refreshToken')
        }
    };

    try {
        dispatch({ type: ADMIN_GET_ME_REQUEST });
        await get('/auth/me')
        .then((res) => dispatch({ type: ADMIN_GET_ME_SUCCESS, payload: res.data }))
        .catch(async error => {
            localStorage.removeItem('token');
            dispatch({ type: ADMIN_GET_ME_FAIL, payload: errorHandler(error) });
            if (error.response?.status === 401) {
                await refreshToken()
            }
        });
    } catch (error) {
        console.log('auth/me catch error', error)
    }
};

export const logout = () => async (dispatch) => {
    localStorage.removeItem('token');
    dispatch({ type: ADMIN_LOGOUT });
};

export const resetPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type:  ADMIN_RESET_PASSWORD_REQUEST });
        const { data } = await post('/auth/forgotPassword', email);
        dispatch({ type: ADMIN_RESET_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADMIN_RESET_PASSWORD_FAIL, payload: errorHandler(error) });
    }
};

export const sendCode = (code) => async (dispatch) => {
    try {
        dispatch({ type:  ADMIN_SEND_CODE_REQUEST });
        const { data } = await post('/auth/sendCode', code);
        dispatch({ type: ADMIN_SEND_CODE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADMIN_SEND_CODE_FAIL, payload: errorHandler(error) });
    }
};

export const changePassword = (password, token) => async (dispatch) => {
    try {
        localStorage.setItem('token', token);
        dispatch({ type:  ADMIN_CHANGE_PASSWORD_REQUEST });
        const { data } = await patch('/auth/changePassword', password)
        .then(() => localStorage.removeItem('token'))
        dispatch({ type: ADMIN_CHANGE_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
        localStorage.removeItem('token')
        dispatch({ type: ADMIN_CHANGE_PASSWORD_FAIL, payload: errorHandler(error) });
    }
};
