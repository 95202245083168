import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material/";
import Icons from "../../../assets/icons";
import styles from "./EditMarginComModal.module.css";
import CustomTextInput from "../../common/CustomTextInput/CustomTextInput";
import TierLayout from "./TierLayout/TierLayout";
import { useActions } from "../../../hooks/useActions";
import { getTiers } from "../../../redux/actions/statisticActions";
import { popup } from "../../../utils/popup";

const EditMarginComModal = ({
  isOpened,
  closeModal,
  header,
  type,
  value,
  setValue,
  handleModalSuccessOpen,
  handleModalErrorOpen,
}) => {
  const { getMe, updatePayments, getPayments, getTiers } = useActions();
  const [commission, setCommission] = useState(value?.commission);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = () => {
    if (errorMessage) return;
    getMe().then(() => {
      const payload = {
        commission: Number(commission),
      };
      updatePayments(value.id, payload)
        .then(() => {
          popup("Payment program successfully updated", "success");
          setCommission(value?.commission);
          handleClose();
          getPayments();
          getTiers();
        })
        .catch((error) =>
          popup("Payment program was not updated with an error", "error")
        );
    });
  };

  const handleClose = () => {
    setCommission(value?.commission);
    closeModal();
  };

  const handleSetErrorMessage = (value, msg) => {
    setErrorMessage((prev) => (value ? msg : prev));
  };
  const checkValidCommission = (value) => {
    setErrorMessage("");
    if (value === "") {
      handleSetErrorMessage(true, "Field is required");
    }
    if ((value || value === 0) && value < 1) {
      handleSetErrorMessage(
        Number(value) < 1,
        "Commission should not be less than 1"
      );
    }
    if (value && Number(value) > 100) {
      handleSetErrorMessage(value, "Commission should not be more than 100%");
    }
  };

  useEffect(() => {
    setCommission(value?.commission);
  }, [value]);

  useEffect(() => {
    checkValidCommission(commission);
  }, [commission]);

  return (
    <Modal open={isOpened} onClose={handleClose}>
      <div className={styles.mainContainer}>
        <Icons.CloseIconDark
          onClick={handleClose}
          className={styles.closeIcon}
        />
        <p className={styles.title}>{header}</p>
        <p className={styles.text}>Change a percentage of seller’s margins</p>
        {type !== "Tiered" && (
          <>
            <div className={styles.formRow}>
              <div className={styles.formInfo}>
                <Icons.PercentageIcon className={styles.icon} />
                <p>Commission</p>
              </div>
              <div className={styles.inputColumn}>
                <CustomTextInput
                  id="selectedCommission"
                  name="selectedCommission"
                  type="number"
                  className={styles.input}
                  additionalProps={{
                    value: commission,
                    onChange: (event) => setCommission(event.target.value),
                    onBlur: (event) =>
                      setCommission(Math.round(Number(commission))),
                  }}
                />
                {errorMessage && (
                  <p className={styles.textHelper}>{errorMessage}</p>
                )}
              </div>
            </div>
            <div className={styles.buttons}>
              <button className={styles.yesBtn} onClick={handleSave}>
                Save
              </button>
            </div>
          </>
        )}
        {type === "Tiered" && <TierLayout tier={value} setTier={setValue} />}
      </div>
    </Modal>
  );
};

EditMarginComModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  header: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
};

export default EditMarginComModal;
