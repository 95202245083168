import {
    INVENTORY_GET_REQUEST,
    INVENTORY_GET_SUCCESS,
    INVENTORY_GET_FAIL,
    INVENTORY_TYPES_GET_REQUEST,
    INVENTORY_TYPES_GET_SUCCESS,
    INVENTORY_TYPES_GET_FAIL
} from '../constants/inventoryConstants';
import { get } from '../../services/fetchData';

export const getInventory = (limit, page, filter="") => async (dispatch) => {
    try {
        dispatch({ type: INVENTORY_GET_REQUEST });
        const { data } = await get(`/inventory/all?limit=${limit}&page=${page}&search=${filter}`);
        dispatch({ type: INVENTORY_GET_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: INVENTORY_GET_FAIL, payload: error.message });
    }
};

export const getInventoryTypes = () => async (dispatch) => {
    try {
        dispatch({ type: INVENTORY_TYPES_GET_REQUEST });
        const { data } = await get(`/inventory/product_types`);
        dispatch({ type: INVENTORY_TYPES_GET_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: INVENTORY_TYPES_GET_FAIL, payload: error.message });
    }
};