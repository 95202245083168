import React from 'react';
import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icons from '../../../assets/icons';
import styles from './CustomSelect.module.css';

const CustomSelect = forwardRef(({ name, additionalProps, options, className, all}, ref) => {
    return (
        <Select
            displayEmpty={all}
            name={name}
            ref={ref}
            className={`${styles.select} ${className}`}
            IconComponent={Icons.SelectFieldIcon}
            MenuProps={{
                MenuListProps: {
                    disablePadding: true,
                },
            }}
            sx={{
                '&:hover': {
                    '&& fieldset': {
                        border: '0px solid transparent',
                    },
                },
                '&& fieldset': {
                    border: '0px solid transparent',
                },
            }}
            {...additionalProps}
        >
            {all && <MenuItem 
                value=""
                className={styles.option}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#FFFFFF',
                    },
                    '&.MuiMenuItem-root': {
                        backgroundColor: '#f7f7f8',
                    },
                }}
            >
                All
            </MenuItem>}
            {options.map((item) => {
                return (
                    <MenuItem
                        key={item.id}
                        value={item.id}
                        className={styles.option}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: '#FFFFFF',
                            },
                            '&.MuiMenuItem-root': {
                                backgroundColor: '#f7f7f8',
                            },
                        }}
                    >
                        {item.value}
                    </MenuItem>
                );
            })}
        </Select>
    );
});

CustomSelect.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    additionalProps: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
    className: PropTypes.any,
};

export default CustomSelect;
