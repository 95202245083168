export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export const ADMIN_GET_ME_REQUEST = 'ADMIN_GET_ME_REQUEST';
export const ADMIN_GET_ME_SUCCESS = 'ADMIN_GET_ME_SUCCESS';
export const ADMIN_GET_ME_FAIL = 'ADMIN_GET_ME_FAIL';

export const ADMIN_RESET_PASSWORD_REQUEST = 'ADMIN_RESET_PASSWORD_REQUEST';
export const ADMIN_RESET_PASSWORD_SUCCESS = 'ADMIN_RESET_PASSWORD_SUCCESS';
export const ADMIN_RESET_PASSWORD_FAIL = 'ADMIN_RESET_PASSWORD_FAIL';

export const ADMIN_CHANGE_PASSWORD_REQUEST = 'ADMIN_CHANGE_PASSWORD_REQUEST';
export const ADMIN_CHANGE_PASSWORD_SUCCESS = 'ADMIN_CHANGE_PASSWORD_SUCCESS';
export const ADMIN_CHANGE_PASSWORD_FAIL = 'ADMIN_CHANGE_PASSWORD_FAIL';

export const ADMIN_SEND_CODE_REQUEST = 'ADMIN_SEND_CODE_REQUEST';
export const ADMIN_SEND_CODE_SUCCESS = 'ADMIN_SEND_CODE_SUCCESS';
export const ADMIN_SEND_CODE_FAIL = 'ADMIN_SEND_CODE_FAIL';
