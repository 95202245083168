import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useActions } from '../../hooks/useActions';
import styles from './RestorePassword.module.css';

const loginSchema = yup.object().shape({
    code: yup.string().min(4).max(4).required(),
});

const VerifyCode = () => {
    const { sendCode } = useActions();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(loginSchema) });

    const onSubmit = (data) => {
        sendCode(data)
    };

    return (
        <div className={styles.box}>
            <p className={styles.greet}>Enter confirmation code</p>
            <p className={styles.title}>
                Enter the confirmation code we sent to your email
            </p>

            <form
                className={styles.form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <label htmlFor='newPassword'>Conformation code</label>
                <input
                    type='code'
                    id='code'
                    name='code'
                    placeholder='Enter conformation code'
                    className={styles.passwordInput}
                    {...register('code')}
                />
                {errors?.password?.message && (
                    <p className={styles.textHelper}>
                        {errors?.password?.message}
                    </p>
                )}

                <input
                    type='submit'
                    value='Submit'
                    readOnly
                    className={styles.submitButton}
                />
                <div className={styles.resendCode} onClick={handleSubmit(onSubmit)}>
                    Resend code
                </div>
            </form>

        </div>
    );
};

export default VerifyCode;
